import React, {useContext} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { red } from '@material-ui/core/colors';
import type {Instrument as InstrumentType} from "../../appstate/InstrumentStore";

import {InstrumentStore, InstrumentStoreContext} from "../../appstate/InstrumentStore";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import confirm from '../Dialogs/ConfirmationDialog';
import prompt from '../Dialogs/TextInputDialog';
import alert from '../Dialogs/AlertDialog';
import getSlug from "speakingurl"

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const Instrument = ({instrument}) => {
    const instrumentStore = useContext(InstrumentStoreContext);
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRename = async() => {
        setAnchorEl(null);

        const newName = await prompt('Rename', 'Enter new name', instrument.name);
        if (newName) {
            instrument.name = newName;
            instrument.id = instrumentStore.getUniqueSlugForName(newName);
        }
    };



    const onStartClick = async () => {
        //alert('Hallo');
        /*
        const text = await prompt('Delete Preset', 'Are your sure? Every deleted preset will come back to you and maybe kill you.');
        if (text) {
            console.log('yes: ' + text);
        } else {
            console.log('no');
        }


         */
        /*
                if (await confirm('Delete Preset', 'Are your sure? Every deleted preset will come back to you and maybe kill you.')) {
                    console.log('yes');
                } else {
                    console.log('no');
                }
        */
    };

    return (
        <>
            <Card className={classes.root}>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings" onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={
                            <div>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {instrument.name}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" component="h3">
                                    {instrument.activePreset?.name || '(No preset)'}
                                </Typography>
                            </div>
                        }
                    />
                <CardActionArea component={RouterLink} to={`/instruments/${instrument.id}`}>
                    <CardMedia
                        className={classes.media}
                        alt={instrument.name}
                        image={instrument.image}
                        title={instrument.name}
                    />
                    {/*
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {`${instrument.name} is a great keyboard`}
                        </Typography>
                    </CardContent>
                        */}
                </CardActionArea>

                <CardActions>
                    <Button size="small" color="primary" component={RouterLink} to={`/instruments/${instrument.id}/configure`}>
                        Configure
                    </Button>
                    <Button size="small" color="primary" component={RouterLink} to={`/instruments/${instrument.id}/collect-presets`}>
                        Collect Presets
                    </Button>
                </CardActions>
            </Card>


            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleRename}>Rename</MenuItem>
                <MenuItem onClick={() => instrumentStore.instruments.splice(instrumentStore.instruments.indexOf(instrument), 1 ) }>Remove</MenuItem>
            </Menu>
        </>
    );
};

export default Instrument;