import { observable, decorate } from "mobx";
import { createContext } from "react";

class SettingsStore {
    presetLearnActive: false
}

decorate(SettingsStore, {
    presetLearnActive: observable
});

export const SettingsStoreContext = createContext(new SettingsStore());