import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import HelpIcon from '@material-ui/icons/Help';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@material-ui/icons/Sync';
import ContactsIcon from '@material-ui/icons/Contacts';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link as RouterLink } from 'react-router-dom';

function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    list: {
        width: 250,
    }
});

export default function Sidebar({open, onDismiss}) {
    const classes = useStyles();

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onKeyDown={onDismiss()}
        >
            <List>
                <ListItemLink primary="Start" to="/" icon={<ContactsIcon />} />
                <ListItemLink primary="Instruments" to="/instruments" icon={<QueueMusicIcon />} />
                <ListItemLink primary="Projects" to="/projects" icon={<AssignmentIcon />} />
            </List>
            <Divider/>
            <List>
                <ListItemLink primary="Sync" to="/sync" icon={<SyncIcon />} />
                <ListItemLink primary="Export" to="/export" icon={<ImportExportIcon />} />
            </List>
            <Divider/>
            <List>
                <ListItem button>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary="Settings"/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><HelpIcon /></ListItemIcon>
                    <ListItemText primary="Help"/>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Drawer anchor="left" open={open} onClose={onDismiss()}>
            {list()}
        </Drawer>
    )
}