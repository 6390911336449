import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InstrumentList from "../InstrumentList/InstrumentList";
import PresetList from "../PresetList/PresetList";
import type {Preset} from "../../util/Presets";
import Midi, { MIDI_EVENT_TYPES } from "../../util/Midi";
import prompt from "../Dialogs/TextInputDialog";
import confirm from '../Dialogs/ConfirmationDialog';

import Switch from '@material-ui/core/Switch';
import { PresetHelper } from "../../util/Presets";

import { observer } from "mobx-react-lite";
import { InstrumentStoreContext } from "../../appstate/InstrumentStore";
import { SettingsStoreContext } from "../../appstate/SettingsStore";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { fade, makeStyles } from "@material-ui/core/styles";
import { getDeviceType } from "../../util/Misc";
import LocalStorage from "../../util/LocalStorage"

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    buttonBar: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default observer(({ props }) => {
    const { instrumentId } = useParams();
    const instrumentStore = useContext(InstrumentStoreContext);
    const settingsStore = useContext(SettingsStoreContext);

    const instrument = instrumentStore.instruments.find(instrument => instrument.id === instrumentId);

    const [midiInputDevices, setMidiInputDevices] = useState(Midi.getMidiInputs());
    const [midiInputName, setMidiInputName] = useState(instrument.midiInputName);

    const [midiOutputDevices, setMidiOutputDevices] = useState(Midi.getMidiOutputs());
    const [midiOutputName, setMidiOutputName] = useState(instrument.midiOutputName);

    const presetList = React.useRef(null);

    const presets = instrument?.presets;

    const classes = useStyles();

    useEffect(() => {
        window.addEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        return function cleanup() {
            window.removeEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        };
    }, []);

    const onReceivedPresetChange = useCallback((receivedPreset: Preset) => {
        const preset = presets.find(preset => PresetHelper.arePresetsEqual(preset, receivedPreset));
    }, [presets]);

    const onMidiDeviceChange = (event) => {
        if (event.target.name === 'midi-input') {
            setMidiInputName(event.target.value);
            instrument.midiInputName = event.target.value;

            LocalStorage.setMidiInput(event.target.value);
        } else {
            setMidiOutputName(event.target.value);
            instrument.midiOutputName = event.target.value;

            LocalStorage.setMidiOutput(event.target.value);
        }
    };

    return instrument && (
        <div style={{padding: 20}}>
            <Typography variant="h5" gutterBottom>
                {instrument.name} - Settings
            </Typography>

            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="midi-input">MIDI Input</InputLabel>
                <NativeSelect
                    value={midiInputName}
                    onChange={onMidiDeviceChange}
                    inputProps={{
                        name: 'midi-input',
                        id: 'midi-input',
                    }}
                >
                    <option value={null}>(None)</option>
                    { midiInputDevices.map((device, index) => <option value={device.name} key={index}>{device.name}</option>) }
                </NativeSelect>
                <FormHelperText>Select the MIDI In device that this instrument should receive from</FormHelperText>
            </FormControl>

            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="midi-output">MIDI Output</InputLabel>
                <NativeSelect
                    value={midiOutputName}
                    onChange={onMidiDeviceChange}
                    inputProps={{
                        name: 'midi-output',
                        id: 'midi-output',
                    }}
                >
                    <option value={null}>(None)</option>
                    { midiOutputDevices.map((device) => <option value={device.name} key={device.name}>{device.name}</option>) }
                </NativeSelect>
                <FormHelperText>Select the MIDI Out device that this instrument should send to</FormHelperText>
            </FormControl>

            <FormGroup row style={{ marginTop: 30 }} className={classes.buttonBar}>
                <Button component={Link} variant="contained" color="primary" to="/">OK</Button>
                {/*<Button component={Link} variant="contained" color="primary" to="/">Cancel</Button>*/}
            </FormGroup>
        </div>
    );
});