import styled, {css} from "styled-components";
import {noselect} from '../../styles';
import {device} from "../../util/MediaQueries";

const heightLarge = `2.4rem`;
const heightSmall = `2rem`;
const height = css`
    height: ${heightSmall};  
    line-height: ${heightSmall};
    border-radius: 10px;
    
    @media ${device.mobileL} {
        height: ${heightLarge};  
        line-height: ${heightLarge}; // line-height sorgt daf�r, dass der Text vertikal zentriert wird!
        border-radius: 15px;              
    }
`;

const fontSize = css`
    font-size: 1rem;
        @media ${device.mobileM} {
            font-size: 1.3rem;
        }
        @media ${device.mobileL} {
            font-size: 1.4rem;
        }
        @media ${device.tablet} {
            font-size: 1.5rem;
        }
`;

export const ButtonWrapper = styled.li`
    display: inline;
    list-style-type: none;
    margin: 5px;
    border: 2px solid #021840;
    padding: 5px 10px;
    ${height};
    ${fontSize};
    //background-color: ${props => props.selected ? '#2337ff' : '#ffffff'};
    //color: ${props => props.selected ? '#ffffff' : '#000000'};
    background-color: ${props => props.selected ? '#ffca04' : '#02206f'};
    color: ${props => props.selected ? '#000000' : '#ffffff'};
    overflow: hidden;
    cursor: pointer;
    ${noselect}
`;