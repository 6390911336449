import React, {useCallback, useContext, useEffect} from 'react';
import {Link as RouterLink, Link} from "react-router-dom";
import styled from 'styled-components';

import { observer } from "mobx-react-lite";
import { observable } from "mobx"
import { ProjectStoreContext } from "../../appstate/ProjectStore"

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import prompt from "../Dialogs/TextInputDialog";
import getSlug from "speakingurl";
import Grid from "@material-ui/core/Grid"
import Instrument from "../InstrumentList/Instrument"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import IconButton from "@material-ui/core/IconButton"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Typography from "@material-ui/core/Typography"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import {makeStyles} from "@material-ui/core/styles"
import {red} from "@material-ui/core/colors"

const ProjectList = styled.ul`
  list-style: none;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export const ProjectListView = observer(({props}) => {
    const projectStore = useContext(ProjectStoreContext);
    const classes = useStyles();

    const [anchorEls, setAnchorEls] = React.useState({});

    const handleClick = (event, projectId) => {
        setAnchorEls({
            ...anchorEls,
            [projectId]: event.currentTarget
        });
    };

    const onNewProjectClick = useCallback(async () => {
        const name = await prompt('New project', 'Enter project name', '');
        if (name) {
            projectStore.projects.push(observable({
                id: projectStore.getUniqueSlugForName(name),
                name: name,
                instrumentLinks: observable([]),
            }));
        }
    }, []);

    const handleClose = (projectId) => {
        const newAnchorEls = {...anchorEls};
        delete newAnchorEls[projectId];
        setAnchorEls(newAnchorEls);
    };

    const handleRename = async(project) => {
        handleClose(project.id);

        const newName = await prompt('Rename', 'Enter new name', project.name);
        if (newName) {
            project.name = newName;
            project.id = projectStore.getUniqueSlugForName(newName);
        }
    };

    return (
        <div style={{ margin: '1rem'}}>
            <h1>Projects</h1>

            <Fab color="primary" size="small" style={{margin: 24}}>
                <AddIcon onClick={() => onNewProjectClick(projectStore)} />
            </Fab>

            <Grid
                container
                spacing={3}
                style={{width: '100%', padding: 24}}
            >
                {projectStore.projects.map(project => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={project.id}>
                        <Card className={classes.root}>
                            <CardHeader
                                action={
                                    <IconButton aria-label="settings" onClick={(e) => handleClick(e, project.id)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {project.name}
                                    </Typography>
                                }
                            />
                            <CardActionArea component={RouterLink} to={`/projects/${project.id}`}>
                                <CardMedia
                                    className={classes.media}
                                    alt={project.name}
                                    image="images/project.png"
                                    title={project.name}
                                />
                            </CardActionArea>

                            <CardActions>
                                <Button size="small" color="primary" component={RouterLink} to={`/projects/${project.id}`}>
                                    Show
                                </Button>
                            </CardActions>
                        </Card>

                        <Menu
                            id={`menu-${project.id}`}
                            anchorEl={anchorEls[project.id]}
                            keepMounted
                            open={Boolean(anchorEls[project.id])}
                            onClose={() => handleClose(project.id)}
                        >
                            <MenuItem onClick={() => handleRename(project)}>Rename</MenuItem>
                            <MenuItem onClick={() => {
                                handleClose(project.id);
                                projectStore.projects.splice(projectStore.projects.indexOf(project), 1 );
                            }}>Remove</MenuItem>
                        </Menu>
                    </Grid>
                ))}
            </Grid>

            {/*
            <ProjectList>
                {projectStore.projects.map(project => (
                    <li key={project.id}>
                        <Link to={`/projects/${project.id}`}>{project.name}</Link>
                    </li>
                ))}
            </ProjectList>
            */}
        </div>
    )
});

export default ProjectListView;