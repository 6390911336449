import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InstrumentList from "../InstrumentList/InstrumentList";
import PresetList from "../PresetList/PresetList";
import type {Preset} from "../../util/Presets";
import Midi, { MIDI_EVENT_TYPES } from "../../util/Midi";
import prompt from "../Dialogs/TextInputDialog";
import confirm from '../Dialogs/ConfirmationDialog';

import Switch from '@material-ui/core/Switch';
import { PresetHelper } from "../../util/Presets";

import { observer } from "mobx-react-lite";
import { InstrumentStoreContext, Instrument } from "../../appstate/InstrumentStore";
import { SettingsStoreContext } from "../../appstate/SettingsStore";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { fade, makeStyles } from "@material-ui/core/styles";
import { getDeviceType } from "../../util/Misc";
import LocalStorage from "../../util/LocalStorage";
import {ProjectStoreContext, ProjectStore} from "../../appstate/ProjectStore"
import {observable} from "mobx"

const presetsPerPage = getDeviceType() === 'phone' ? 10 : 20;

function collectPresetsForInstrument(instrument: Instrument, projectStore: ProjectStore)
{
    const collectedPresets = [];

    projectStore.projects.forEach((project) => {
        project.instrumentLinks.filter((instrumentLink) => instrumentLink.instrument === instrument).forEach((instrumentLink) => {
            instrumentLink.presets.forEach((preset) => {
                collectedPresets.push({...preset, project: project.name});
            })
        })
    });

    collectedPresets.sort((a, b) => {
        if (a.bankMSB !== b.bankMSB) {
            return a.bankMSB - b.bankMSB;
        } else if (a.bankLSB !== b.bankLSB) {
            return a.bankLSB - b.bankLSB;
        } else {
            return a.programNumber - b.programNumber;
        }
    });

    return observable(collectedPresets);
}

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonBar: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default observer(({ props }) => {
    const { instrumentId } = useParams();
    const instrumentStore = useContext(InstrumentStoreContext);
    const projectStore = useContext(ProjectStoreContext);

    const instrument = instrumentStore.instruments.find(instrument => instrument.id === instrumentId);

    const [midiInputDevices, setMidiInputDevices] = useState(Midi.getMidiInputs());
    const [midiInputName, setMidiInputName] = useState(instrument.midiInputName);

    const [midiOutputDevices, setMidiOutputDevices] = useState(Midi.getMidiOutputs());
    const [midiOutputName, setMidiOutputName] = useState(instrument.midiOutputName);

    const [presets, setPresets] = useState(collectPresetsForInstrument(instrument, projectStore));

    const presetList = React.useRef(null);


    const classes = useStyles();

    useEffect(() => {
        const changeMidiDevice = () => {
            Midi.changeMidiInput(instrument.midiInputName);
            Midi.changeMidiOutput(instrument.midiOutputName);

            console.debug(`connected with ${instrument?.name}: ${instrument.midiInputName}`);
        };

        // TODO: Das ist nat�rlich hacky und geht so nicht.
        Midi.isInitialized ? changeMidiDevice() : setTimeout(changeMidiDevice, 2000);
    }, []);

    useEffect(() => {
        window.addEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        return function cleanup() {
            window.removeEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        };
    }, []);

    const onReceivedPresetChange = useCallback((e) => {
        const receivedPreset: Preset = e.detail.preset;

        const preset = presets.find(preset => PresetHelper.arePresetsEqual(preset, receivedPreset));
        if (preset) {
            instrument.activePreset = preset;
        }

    }, [presets, instrument]);

    const onMidiDeviceChange = (event) => {
        if (event.target.name === 'midi-input') {
            setMidiInputName(event.target.value);
            instrument.midiInputName = event.target.value;

            LocalStorage.setMidiInput(event.target.value);
        } else {
            setMidiOutputName(event.target.value);
            instrument.midiOutputName = event.target.value;

            LocalStorage.setMidiOutput(event.target.value);
        }
    };

    const onPresetClick = (e, preset: Preset) => {
        Midi.sendPresetToCurrentOutput(preset);
        instrument.activePreset = preset;
    };

    return instrument && (
        <div style={{padding: 20}}>
            <Typography variant="h5" gutterBottom>
                {instrument.name} - Presets from all projects
            </Typography>

            <PresetList
                presets={presets}
                presetsPerPage={presetsPerPage}
                searchText=""
                selectedPreset={instrument.activePreset}
                onPresetClick={(e, preset) => onPresetClick(e, preset)}
            />

            <div style={{ marginTop: '2rem' }}>
                <table>
                    <thead>
                        <tr>
                            <td><b>Bank</b></td>
                            <td><b>Program</b></td>
                            <td><b>Preset name</b></td>
                            <td><b>Used in project</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        { presets.map((preset: Preset, index: number) => <tr key={index} style={{ background: instrument.activePreset && PresetHelper.arePresetsEqual(preset, instrument.activePreset) ? 'yellow' : 'white'}}><td>{ preset.bankMSB }-{ preset.bankLSB }</td><td>{ preset.programNumber }</td><td>{ preset.name }</td><td>{ preset.project }</td></tr>)}
                    </tbody>
                </table>
            </div>

            <FormGroup row style={{ marginTop: 30 }} className={classes.buttonBar}>
                <Button component={Link} variant="contained" color="primary" to="/">OK</Button>
                {/*<Button component={Link} variant="contained" color="primary" to="/">Cancel</Button>*/}
            </FormGroup>
        </div>
    );
});