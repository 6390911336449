import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { confirmable, createConfirmation } from 'react-confirm';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField
} from "@material-ui/core";

const TextInputDialog = ({show, proceed, title = '', message, defaultText = '', textFieldLabel = ''}) => {
    const [text, setText] = useState(defaultText);

    const handleChangeText = (event) => {
        setText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            proceed(text);
            event.preventDefault();
        }
    };

    return (
        <Dialog open={show} onClose={() => proceed(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="text"
                    value={text}
                    label={textFieldLabel}
                    onChange={handleChangeText}
                    onKeyDown={handleKeyPress}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => proceed(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => proceed(text)} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

TextInputDialog.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
    options: PropTypes.object
};

const prompt = createConfirmation(confirmable(TextInputDialog));

export default function promptWrapper(title, message, defaultText, textFieldLabel) {
    return prompt({ title, message, defaultText, textFieldLabel });
};