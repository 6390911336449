
import React from 'react';
import PropTypes from 'prop-types';

import { confirmable, createConfirmation } from 'react-confirm';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";

const ConfirmationDialog = ({show, proceed, title, text, options}) => {
    return (
        <Dialog
            open={show}
            onClose={() => proceed(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={options && options.fullWidth}
            maxWidth="sm"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => proceed(false)}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => proceed(true)} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
    options: PropTypes.object
};

const confirm = createConfirmation(confirmable(ConfirmationDialog));

export default function confirmWrapper(title, text, options = {}) {
    return confirm({ title, text, options });
};