export const DEVICE_TYPES = Object.freeze({
    DESKTOP: 'desktop',
    PHONE: 'phone',
    TABLET: 'tablet',
});

export function getDeviceType() {
    if (/Mobi|Android|iPad|iPhone|Windows Phone|BlackBerry|Tablet|Kindle|Silk/.test(navigator.userAgent)) {
        if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
            return DEVICE_TYPES.PHONE;
        } else {
            return DEVICE_TYPES.TABLET;
        }
    }
    return DEVICE_TYPES.DESKTOP;
}

export function isOnline() {
    return window.navigator.onLine;
}