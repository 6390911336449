export const html = `
<html>
<style>
table { border: no; border-collapse: collapse; }
td { font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif; padding: 5pt; font-size: 12px; }
th { font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif; padding: 5pt; font-size: 12px; }
td.odd { background-color: #d5d5d5; color: black; }
</style><table>
<tr>
<th>Partition</td>
<th>Bank</td>
<th>Location</td>
<th>Name</td>
<th>Category</td>
<th>Version</td>
<th>Piano A</td>
<th>Samp Lib A</td>
<th>Piano B</td>
<th>Samp Lib B</td>
<th>Info</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>01:1</td>
<td class=odd>Studio Grand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>01:2</td>
<td>Bright Grand</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>(No sound)</td>
<td>White Grand Sml 6.1</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>01:3</td>
<td class=odd>Baby Upright</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>D70AP_MKSEP1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>01:4</td>
<td>MKS20 Piano</td>
<td>Grand</td>
<td>7</td>
<td>MKS20 A.Piano1  Lrg 5.1</td>
<td>StringOrchestra_ _ste SRv4</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>D70AP_MKSEP1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>01:5</td>
<td class=odd>Atmo BrightGrand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>02:1</td>
<td>Mk I Deep</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>02:2</td>
<td class=odd>Mk I Close</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>02:3</td>
<td>Epiano 7 Amped</td>
<td>EPiano1</td>
<td>7</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>FullStrings1__ste SRv4</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>FullStrings1__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>02:4</td>
<td class=odd>Mk V ClsIdeal</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>FullStrings1__ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>02:5</td>
<td>Bright Tines</td>
<td>EPiano1</td>
<td>7</td>
<td>EP5 Bright Tines CustomMod 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>EP5 Bright Tines CustomMod 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>03:1</td>
<td class=odd>SparkleTop   </td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>EP6 SparkleTop Vintage67 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>EP6 SparkleTop Vintage67 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>03:2</td>
<td>Rubba Rhodes</td>
<td>EPiano2</td>
<td>7</td>
<td>DX7_RubbaRhodes_Lrg_5.4</td>
<td>FullStrings1__ste SRv4</td>
<td>DX7_RubbaRhodes_Lrg_5.4</td>
<td>FullStrings1__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>03:3</td>
<td class=odd>Cool Clavinet  </td>
<td class=odd>Clavinet</td>
<td class=odd>6</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>03:4</td>
<td>CP-80         </td>
<td>Grand</td>
<td>6</td>
<td>Electric Grand 1 CP80  5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>03:5</td>
<td class=odd>CP-80 Amped   </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>04:1</td>
<td>Wurli 2 Amped</td>
<td>EPiano2</td>
<td>7</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>FullStrings1__ste SRv4</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>FullStrings1__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>04:2</td>
<td class=odd>Dance Upright MW</td>
<td class=odd>Upright</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>D70AP_MKSEP1</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>D70AP_MKSEP1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>04:3</td>
<td>Piano Pad</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Pad Piano</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>MKS P3 DX7</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>04:4</td>
<td class=odd>M1 Piano Heaven</td>
<td class=odd>Synth</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>M1 PianoHeaven</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>MKS P3 DX7</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>04:5</td>
<td>Meditate Piano</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>SRX11 MEDITATE b</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>05:1</td>
<td class=odd>DX7 EP1      1-1</td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>Electric Grand 1 CP80  5.3</td>
<td class=odd>DX7_E.Piano1_Tine</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>05:2</td>
<td>Double Tines 1-1</td>
<td>EPiano1</td>
<td>6</td>
<td>Electric Grand 1 CP80  5.3</td>
<td>DX7 II Double Tines_Tine</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>05:3</td>
<td class=odd>Full   Tines 1-1</td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>Electric Grand 1 CP80  5.3</td>
<td class=odd>DX7 II FullTines_Tine</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>05:4</td>
<td>DX7 FullTines11s</td>
<td>EPiano1</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>DX7 II FullTines</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>DX7 II FullTines</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>05:5</td>
<td class=odd>DX Chorus ModVib</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>FMPiano_PH</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>06:1</td>
<td>DX7</td>
<td>EPiano1</td>
<td>7</td>
<td>DX7_FullTines_Lrg_5.4</td>
<td>StringOrchestra_ _ste SRv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>06:2</td>
<td class=odd>DX7 Crystal</td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>DX7_FullTines_Lrg_5.4</td>
<td class=odd>StringOrchestra_ _ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>DX7 Crystal RHD</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>06:3</td>
<td>DX7 Heaven</td>
<td>EPiano1</td>
<td>7</td>
<td>DX7_FullTines_Lrg_5.4</td>
<td>StringOrchestra_ _ste SRv4</td>
<td>EP5 Bright Tines CustomMod 5.3</td>
<td>SYAFMVOICE</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>06:4</td>
<td class=odd>DX7 Bellvoices</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>DX7_FullTines_Lrg_5.4</td>
<td class=odd>StringOrchestra_ _ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>M1 Bellvoices</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>06:5</td>
<td>Unnamed</td>
<td>None</td>
<td>7</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>DX7_FullTines_Lrg_5.4</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>07:1</td>
<td class=odd>XV5080 MKS Piano</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>xv5080 MKS PNO1 b</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>xv5080 MKS PNO1 b</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>07:2</td>
<td>01w Dyno Piano  </td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>O1w DynoPiano</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>07:3</td>
<td class=odd>S90ES ModStrings</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>S90ES + JD RHOD</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>07:4</td>
<td>KORG M1 01W</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>KORG M1 01W</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>07:5</td>
<td class=odd>DX Pad          </td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>DXPad</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>MKS P3 DX7</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>08:1</td>
<td>Westcoast Pin   </td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>K25_Westcoast Pin</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>08:2</td>
<td class=odd>K25 Dyno MKS</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>K25 DYNO MKS APEP</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>08:3</td>
<td>JD800 MIDI Piano</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>JD800 MIDIED PNO</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>08:4</td>
<td class=odd>Rhodes MW Brass</td>
<td class=odd>Wind</td>
<td class=odd>7</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>BrassSect Pop 1__BR ste 2.0</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>HornTrio+Baryton_Split_ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>08:5</td>
<td>Pop Piano 1</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>xv5080 MKS PNO1 b</td>
<td>White Grand Sml 6.1</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>09:1</td>
<td class=odd>Moogish Lead</td>
<td class=odd>Lead</td>
<td class=odd>7</td>
<td class=odd>EP6 SparkleTop Vintage67 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>EP6 SparkleTop Vintage67 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>09:2</td>
<td>Soft Lead</td>
<td>User</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>09:3</td>
<td class=odd>Pad Lead Split</td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>09:4</td>
<td>Solo Saw Lead   </td>
<td>Lead</td>
<td>7</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>09:5</td>
<td class=odd>Doctor Square</td>
<td class=odd>Synth</td>
<td class=odd>6</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>10:1</td>
<td>Flutish Brass   </td>
<td>Synth</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Flutish Brass</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>10:2</td>
<td class=odd>M1 Solo Synth</td>
<td class=odd>Synth</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>M1 Solo Synth</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>P5 SuperLeadUnison BR</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>10:3</td>
<td>EP Panflut Split</td>
<td>EPiano1</td>
<td>7</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>BolivianPanpipe__PSv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>10:4</td>
<td class=odd>g0rg0 MS</td>
<td class=odd>Synth</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>g0rg0 MS</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>MKS P3 DX7</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>10:5</td>
<td>CD Zamfir</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>CD Zamfir</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>11:1</td>
<td class=odd>Dont Jump  AT</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>11:2</td>
<td>Synth Brass Fast</td>
<td>Grand</td>
<td>7</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>80s Synth Brass</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>80s Synth Brass Vibrato</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>11:3</td>
<td class=odd>Synth Brass Slow</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>80s Synth Brass</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>80s Synth Brass Vibrato</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>11:4</td>
<td>Stabby Horns</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Stabby Horns</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>11:5</td>
<td class=odd>Big Synt Horns</td>
<td class=odd>Synth</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>12:1</td>
<td>Brass Section 2</td>
<td>Wind</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Brass Section Pop 2_ste BR</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Stabby Horns</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>12:2</td>
<td class=odd>Brass Swell</td>
<td class=odd>Wind</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>hanon-spike fab BRASS</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>hanon-spike fab BRASS</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>12:3</td>
<td>Go And Jump     </td>
<td>Synth</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Stabby Horns</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>12:4</td>
<td class=odd>Epic Flute      </td>
<td class=odd>Wind</td>
<td class=odd>6</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>Recorder M400_Mellotron_v4</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>Recorder M400_Mellotron_v4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>12:5</td>
<td>Pop Brass</td>
<td>Wind</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>BrassSect Pop 1__BR ste 2.0</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>HornTrio+Baryton_Split_ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>13:1</td>
<td class=odd>PianBrass</td>
<td class=odd>Wind</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>BrassSect Pop 1__BR ste 2.0</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>HornTrio+Baryton_Split_ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>20:5</td>
<td>Searching EP</td>
<td>EPiano1</td>
<td>6</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>01:1</td>
<td class=odd>XL-Strings    MW</td>
<td class=odd>String</td>
<td class=odd>6</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>22violins__ste SRv4</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>01:2</td>
<td>Slow Strings  MW</td>
<td>String</td>
<td>6</td>
<td>Not found: (Unknown)</td>
<td>22violins__ste SRv4</td>
<td>Clavinet D6  5.0</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>01:3</td>
<td class=odd>Orch Strings</td>
<td class=odd>String</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>22violins__ste SRv4</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>01:4</td>
<td>Fast Strings    </td>
<td>String</td>
<td>6</td>
<td>Not found: (Unknown)</td>
<td>StringOrchestra_ _ste SRv4</td>
<td>Clavinet D6  5.0</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>01:5</td>
<td class=odd>Disco Strings   </td>
<td class=odd>String</td>
<td class=odd>7</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Pizzicato_OrchStr_ste KHv4</td>
<td class=odd>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>02:1</td>
<td>Adagio Strings p</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Adagio Strings</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>80s Synth Brass Vibrato</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>02:2</td>
<td class=odd>Adagio Strings f</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Adagio Strings 2</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>80s Synth Brass Vibrato</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>02:3</td>
<td>Symph Short Soft</td>
<td>String</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Symphobia Shorts Soft</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>80s Synth Brass Vibrato</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>02:4</td>
<td class=odd>Symph Short Hard</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Symphobia Shorts Hard</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>80s Synth Brass Vibrato</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>02:5</td>
<td>Symphob Mindbend</td>
<td>String</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Mindbend</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>80s Synth Brass Vibrato</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>03:1</td>
<td class=odd>Blockbuster</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Blockbuster</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>80s Synth Brass Vibrato</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>03:2</td>
<td>Symph Mousehunt</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Mousehunt</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>80s Synth Brass Vibrato</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>03:3</td>
<td class=odd>Orchestra Hit</td>
<td class=odd>Acoustic</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>TR-Rack - ST Major O.Hit</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>hanon-spike fab BRASS</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>03:4</td>
<td>Pop Orchestr Hit</td>
<td>Acoustic</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>TR-Rack - ST Power Pop Hit</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>hanon-spike fab BRASS</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>03:5</td>
<td class=odd>Pop Hit 2</td>
<td class=odd>Acoustic</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>TR-Rack - ST Power Pop Hit</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Fame Epic Synth</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>04:1</td>
<td>Soft Fat Pad  MW</td>
<td>Pad</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>04:2</td>
<td class=odd>SawPad  B-Sweep</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>04:3</td>
<td>Fast Analog Pad</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Soft Analog</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>04:4</td>
<td class=odd>Soft Pad  MW</td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>04:5</td>
<td>Super Saw       </td>
<td>Pad</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>05:1</td>
<td class=odd>M1 Singers</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>M1 Singers</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>05:2</td>
<td>Chor FemMale art</td>
<td>Vocal</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Ch Fem&Male</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>05:3</td>
<td class=odd>Ch Greg art   MW</td>
<td class=odd>Vocal</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Ch Greg art</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Jazz Doos</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>05:4</td>
<td>Jazz Doos</td>
<td>Acoustic</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Jazz Doos</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>hanon-spike fab BRASS</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>05:5</td>
<td class=odd>Voxos Choir</td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Voxos</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>80s Synth Brass Vibrato</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>06:1</td>
<td>Fame Synth Fast</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Fame Epic Synth</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Fame Epic Synth</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>06:2</td>
<td class=odd>Fame Synth Decay</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Fame Epic Synth</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Fame Epic Synth</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>06:3</td>
<td>Fame Synth EQed</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Fame Epic Synth</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Fame Epic Synth</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>06:4</td>
<td class=odd>Fame Synth Arp</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Fame Epic Synth</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Fame Epic Synth</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>06:5</td>
<td>Fame Synth Arp 2</td>
<td>Grand</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Fame Epic Synth</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Fame Epic Synth</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>07:1</td>
<td class=odd>Analog Score  </td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>07:2</td>
<td>Slow Splitter AT</td>
<td>Pad</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>07:3</td>
<td class=odd>ArpicLand     MW</td>
<td class=odd>Fantasy</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>07:4</td>
<td>SY99 AFM Voice</td>
<td>Synth</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SYAFMVOICE</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>MKS P3 DX7</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>07:5</td>
<td class=odd>M1 Sparkling Pad</td>
<td class=odd>Synth</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>M1 SparklingPad</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>MKS P3 DX7</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>08:1</td>
<td>Marscape</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Marscape</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>08:2</td>
<td class=odd>M1 Universe</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>M1 Universe</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>08:3</td>
<td>M1 Lore</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>M1 Lore</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>08:4</td>
<td class=odd>White Noise</td>
<td class=odd>FX</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>White Noise</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>White Noise</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>08:5</td>
<td>SE Them</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>SE Them</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>09:1</td>
<td class=odd>Vibraphone MW</td>
<td class=odd>None</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Vibes_Medium_DVv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Vibes_Medium_DVv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>09:2</td>
<td>Jollevibe     MW</td>
<td>None</td>
<td>7</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>Vibes_Medium_DVv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>09:3</td>
<td class=odd>Celeste</td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Celeste__STv5</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Celeste__STv5</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>09:4</td>
<td>Marimba</td>
<td>None</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Marimba__STv5</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Marimba__STv5</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>09:5</td>
<td class=odd>Steel Drums</td>
<td class=odd>Pluck</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>Steel Drums</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>10:1</td>
<td>Accordion</td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Accordion1_Carina _PSv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Accordion1_Carina _PSv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>10:2</td>
<td class=odd>Harp</td>
<td class=odd>Pluck</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Harp__DVv4</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Harp__DVv4</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>10:3</td>
<td>M1 Bellvoices</td>
<td>Synth</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>M1 Bellvoices</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>MKS P3 DX7</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>10:4</td>
<td class=odd>Flute</td>
<td class=odd>None</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Flute</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Marimba__STv5</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>10:5</td>
<td>M1 Woodwinds</td>
<td>None</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>M1 OrchWood</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Marimba__STv5</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>11:1</td>
<td class=odd>Moves Like Ja</td>
<td class=odd>User</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Recorder M400_Mellotron_v4</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>11:2</td>
<td>Aint Nobod Intro</td>
<td>Synth</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>MM Fat Square Bass BR</td>
<td>Clavinet D6  5.0</td>
<td>StringOrchestra_ _ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>11:3</td>
<td class=odd>Aint Nobod Bells</td>
<td class=odd>Synth</td>
<td class=odd>7</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>Marimba__STv5</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>StringOrchestra_ _ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>11:4</td>
<td>Axel F Polysynth</td>
<td>Synth</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>11:5</td>
<td class=odd>Poker Face Vrs  </td>
<td class=odd>Synth</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>12:1</td>
<td>Beat It Intro   </td>
<td>Synth</td>
<td>6</td>
<td>EP5 Bright Tines CustomMod 5.3</td>
<td>Not found: (Unknown)</td>
<td>Not found: (Unknown)</td>
<td>Beat It</td>
<td>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>12:2</td>
<td class=odd>Boogied Down</td>
<td class=odd>Bass</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>12:3</td>
<td>Analog Surviver</td>
<td>Pad</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>TR-Rack - ST Major O.Hit</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>19:1</td>
<td class=odd>Happy       </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>19:2</td>
<td>Pusher Love </td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>20:3</td>
<td class=odd>Outer Jam   </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>20:4</td>
<td>Outer Jam   </td>
<td>Grand</td>
<td>7</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>FullOrchestra__ste SRv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>20:5</td>
<td class=odd>Outer Jam   </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>01:1</td>
<td>Jimmie Smith B3 </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>01:2</td>
<td class=odd>Lord In Time </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>(No sound)</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>(No sound)</td>
<td class=odd>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>01:3</td>
<td>Jazzy Joey   B3 </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>01:4</td>
<td class=odd>Booker T        </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>01:5</td>
<td>Easy Does It  MW</td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>02:1</td>
<td class=odd>Jimmie Smith B3 </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>02:2</td>
<td>BlueRoom B3   </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>02:3</td>
<td class=odd>Early Bird B3   </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>02:4</td>
<td>Rock Organ      </td>
<td>Organ</td>
<td>6</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>02:5</td>
<td class=odd>Basis</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>03:1</td>
<td>FullStops     MW</td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>03:2</td>
<td class=odd>Avishai Organ</td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>03:3</td>
<td>Jazzy Joey   B3 </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>03:4</td>
<td class=odd>Walking on Sun</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>22violins__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>03:5</td>
<td>Farfisa         </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>04:1</td>
<td class=odd>Farfisa         </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>04:2</td>
<td>B3 Theater 1    </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>04:3</td>
<td class=odd>CathedralOrg 3</td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>CathedralOrgan3__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>CathedralOrgan3__ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>04:4</td>
<td>Notre Dame    MW</td>
<td>Organ</td>
<td>6</td>
<td>French Harpsi 1D Lo8 Lo4 5.0</td>
<td>CathedralOrgan5__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>CathedralOrgan5__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>04:5</td>
<td class=odd>Gospel PianoOrga</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>05:1</td>
<td>Piano 1 1</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Piano 1 1 mono</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>05:2</td>
<td class=odd>Piano 1 3 127</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>Piano 1 3 127</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>05:3</td>
<td>Piano 2 1</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Piano 2 1 mono</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>05:4</td>
<td class=odd>Piano 3 chorus</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>05:5</td>
<td>Piano 3 3 127</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Not found: (Unknown)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>06:1</td>
<td class=odd>E-Piano 1 87</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>E Piano 1 87  mono sm</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>06:2</td>
<td>E-Piano 1 7 127</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>E Piano 1 7 127  mono sm</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>06:3</td>
<td class=odd>E-Piano 1 7 87</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>E Piano 1 7 87  mono sm</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>06:4</td>
<td>E-Piano 1 87 cho</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>E Piano 1 87 chorus</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>06:5</td>
<td class=odd>E-Piano 2 87cho</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>E Piano 2 87 chorus</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>07:1</td>
<td>E-Piano 2 127cho</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>E Piano 2 127 chorus</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>07:2</td>
<td class=odd>E-Piano 2 7 87</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>E Piano 2 7 87  mono</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>07:3</td>
<td>Clavi 1</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Not found: (Unknown)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>07:4</td>
<td class=odd>Harpsichord</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>07:5</td>
<td>Vibraphone 3</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Vibraphone 3</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>08:1</td>
<td class=odd>Vibraphone 1</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>Vibraphone 1 mono</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>09:1</td>
<td>Creepin</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>09:2</td>
<td class=odd>Butterfly</td>
<td class=odd>None</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EP5 Bright Tines CustomMod 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>09:3</td>
<td>Let it Ride </td>
<td>EPiano1</td>
<td>7</td>
<td>EP5 Bright Tines CustomMod 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>FullOrchestra__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>09:4</td>
<td class=odd>Still Dre </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>D70AP_MKSEP1</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>D70AP_MKSEP1</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>09:5</td>
<td>Stabby Horns</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Stabby Horns</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>10:1</td>
<td class=odd>Jimmie Smith B3 </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>10:2</td>
<td>Jimmie Smith B3 </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>10:4</td>
<td class=odd>BoogalJoey   B3 </td>
<td class=odd>Organ</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>10:5</td>
<td>Jimmie Smith B3 </td>
<td>Organ</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>12:1</td>
<td class=odd>Basis</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>12:2</td>
<td>Jim Speaks</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>12:3</td>
<td class=odd>I Wonder Why</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>12:4</td>
<td>Groove Elation</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>12:5</td>
<td class=odd>Protocol</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>13:1</td>
<td>Bitter Sweet Lem</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>13:2</td>
<td class=odd>Dingo Rock C</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>13:3</td>
<td>Christina</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>13:4</td>
<td class=odd>Christina Solo  </td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>13:5</td>
<td>Friday Night</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>14:1</td>
<td class=odd>Basis</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>14:2</td>
<td>Creepin</td>
<td>Organ</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>14:5</td>
<td class=odd>Slow Splitter AT</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>15:1</td>
<td>Im coming out</td>
<td>EPiano1</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>80s Synth Brass</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>15:2</td>
<td class=odd>Get lucky</td>
<td class=odd>None</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>15:3</td>
<td>GirlOnFire CP80</td>
<td>Grand</td>
<td>7</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>White Grand Sml 6.1</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>15:4</td>
<td class=odd>Best of my love </td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>Electric Grand 1 CP80  5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>15:5</td>
<td>Blame it on</td>
<td>EPiano1</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>16:1</td>
<td class=odd>Teardrops   </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>16:2</td>
<td>I wanna be EP 2</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>16:3</td>
<td class=odd>I wanna be EP 2</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>16:4</td>
<td>Wild and Free</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>16:5</td>
<td class=odd>Cant feel Pad</td>
<td class=odd>None</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Flute</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>17:2</td>
<td>Waiting for love</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>Piano 2 1 mono</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>FMPiano_PH</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>17:3</td>
<td class=odd>Dangerous</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>17:4</td>
<td>Rude Boy Suprsaw</td>
<td>Pad</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>17:5</td>
<td class=odd>Dont say much EP</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>18:1</td>
<td>Signed Grand</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>(No sound)</td>
<td>White Grand Sml 6.1</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>18:2</td>
<td class=odd>Nutbush City   </td>
<td class=odd>Clavinet</td>
<td class=odd>7</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>18:3</td>
<td>7 years     </td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>18:4</td>
<td class=odd>Come Over Split</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>EP5 Bright Tines CustomMod 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>18:5</td>
<td>Uptown Funk</td>
<td>None</td>
<td>7</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>Uptown_Funk_2</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>19:1</td>
<td class=odd>Living for EP 2</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>19:2</td>
<td>Rock Your Body</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>Ballerina Bells</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>M1 Bellvoices</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>19:3</td>
<td class=odd>Cant Stop Feelin</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>Ballerina Bells</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>19:4</td>
<td>Bright Grand</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>(No sound)</td>
<td>White Grand Sml 6.1</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>19:5</td>
<td class=odd>Every little thi</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>20:1</td>
<td>Streets 80s Pad</td>
<td>Synth</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Marscape</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>One World</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>20:3</td>
<td class=odd>Hymn Piano Choir</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>Voxos</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>Ch Fem&Male</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>20:4</td>
<td>Hymn Hit</td>
<td>Acoustic</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>TR-Rack - ST Power Pop Hit</td>
<td>Electric Grand 1 CP80  5.3</td>
<td>Fame Epic Synth</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>20:5</td>
<td class=odd>Adventures of Li</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>01:1</td>
<td>Never InteRhodes</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>SRX11 MEDITATE b</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>01:2</td>
<td class=odd>Never Interrupt</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>SRX11 MEDITATE b</td>
<td class=odd>EP5 Bright Tines CustomMod 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>01:3</td>
<td>Walking Upright</td>
<td>Grand</td>
<td>7</td>
<td>Not found: (Unknown)</td>
<td>D70AP_MKSEP1</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>D70AP_MKSEP1</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>01:4</td>
<td class=odd>Cruel Soft Pad</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>01:5</td>
<td>Life is teaching</td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>02:1</td>
<td class=odd>Yearnin</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP5 Bright Tines CustomMod 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EP5 Bright Tines CustomMod 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>02:2</td>
<td>Searching EP</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>02:3</td>
<td class=odd>Feel The Love</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>02:4</td>
<td>I want you EP</td>
<td>EPiano1</td>
<td>7</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>(No sound)</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>03:1</td>
<td class=odd>Flying High EP</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>03:2</td>
<td>Talk To Me</td>
<td>EPiano2</td>
<td>7</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>FullStrings1__ste SRv4</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>FullStrings1__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>03:3</td>
<td class=odd>Tomorrow</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>03:4</td>
<td>Free</td>
<td>None</td>
<td>7</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>(No sound)</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>03:5</td>
<td class=odd>Little Bit Piano</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>04:1</td>
<td>Nothing Compares</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>04:2</td>
<td class=odd>Movin</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>04:3</td>
<td>Movin Rhodes</td>
<td>Grand</td>
<td>7</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>FullOrchestra__ste SRv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>04:4</td>
<td class=odd>Snow Crystal EP</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>04:5</td>
<td>Something</td>
<td>None</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>05:1</td>
<td class=odd>So easy</td>
<td class=odd>None</td>
<td class=odd>7</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>05:2</td>
<td>Make Me Wanna</td>
<td>EPiano1</td>
<td>7</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Soft Analog</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>Soft Analog</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>05:3</td>
<td class=odd>Vamp Organ</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>05:4</td>
<td>Vamp Rhodes</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>05:5</td>
<td class=odd>Vamp RhodesOrgan</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>06:1</td>
<td>You are rich</td>
<td>None</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>(No sound)</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>06:2</td>
<td class=odd>- Rich Lead</td>
<td class=odd>Synth</td>
<td class=odd>7</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>06:3</td>
<td>- End Flanger</td>
<td>EPiano1</td>
<td>7</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>06:4</td>
<td class=odd>Artificial Piano</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>06:5</td>
<td>Walking in Memph</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>07:1</td>
<td class=odd>Home - Melody</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>07:2</td>
<td>Home - Backings</td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>07:3</td>
<td class=odd>Studio Grand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>07:4</td>
<td>Air Piano       </td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>Baby Upright Nordiska  Sml 5.4</td>
<td>D70AP_MKSEP1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>07:5</td>
<td class=odd>Traumo Grand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>08:1</td>
<td>Traumo Grand</td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>08:2</td>
<td class=odd>Studio Grand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>08:3</td>
<td>Circles</td>
<td>None</td>
<td>7</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>08:4</td>
<td class=odd>I was told Uprig</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Baby Upright Nordiska  Sml 5.4</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>08:5</td>
<td>Life is my lover</td>
<td>EPiano1</td>
<td>7</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>SynthPad1__ste CLv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>09:1</td>
<td class=odd>Studio Grand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>09:2</td>
<td>Beauty of the</td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>09:3</td>
<td class=odd>Schnupftabak</td>
<td class=odd>None</td>
<td class=odd>7</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>(No sound)</td>
<td class=odd>French Harpsi 1D Lo8 Lo4 5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>09:4</td>
<td>PIANO BASS</td>
<td>None</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>09:5</td>
<td class=odd>RHODE BASS</td>
<td class=odd>None</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>10:1</td>
<td>Live Mode Rhodes</td>
<td>User</td>
<td>6</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td>Clavinet D6  5.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>10:2</td>
<td class=odd>Live Mode Lead</td>
<td class=odd>User</td>
<td class=odd>6</td>
<td class=odd>EP6 SparkleTop Vintage67 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>VCO Oct Lead</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>10:3</td>
<td>PIANO BASS</td>
<td>None</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>10:4</td>
<td class=odd>Upright Pn Split</td>
<td class=odd>Bass</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Contra Bass_Plucked_STv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Contra Bass_Plucked_STv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>10:5</td>
<td>Rock YouBody Pad</td>
<td>Grand</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>Soft Analog</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Ch Fem&Male</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>11:1</td>
<td class=odd>Soft Fat Pad  MW</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>11:2</td>
<td>Doctor Square</td>
<td>Synth</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>11:5</td>
<td class=odd>Mad PhaserRhodes</td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>80s Synth Brass</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>12:1</td>
<td>Mad PhaserRhodes</td>
<td>EPiano1</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>80s Synth Brass</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>12:2</td>
<td class=odd>Mad PhaserRhodes</td>
<td class=odd>EPiano1</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>80s Synth Brass</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>13:1</td>
<td>Like a feather</td>
<td>EPiano1</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>13:2</td>
<td class=odd>Head Jump  AT</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>13:3</td>
<td>Rock your body</td>
<td>User</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>Vibes_Medium_DVv4</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>13:4</td>
<td class=odd>Jam        </td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>MJ Jam FX</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>13:5</td>
<td>Nod your head   </td>
<td>String</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>Symphobia Shorts Soft</td>
<td>Clavinet D6  5.0</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>14:1</td>
<td class=odd>Love Never  </td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>FullOrchestra__ste SRv4</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>14:2</td>
<td>Rock Youlog Pad</td>
<td>Grand</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>Soft Analog</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>Ch Fem&Male</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>14:3</td>
<td class=odd>Rock With You </td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>14:4</td>
<td>SparkleTop   </td>
<td>EPiano1</td>
<td>6</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>(No sound)</td>
<td>EP6 SparkleTop Vintage67 5.3</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>15:1</td>
<td class=odd>Pingouin Tines</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>16:1</td>
<td>Opener</td>
<td>User</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>22violins__ste SRv4</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>P5 SuperLeadUnison BR</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>16:2</td>
<td class=odd>Ethiopia Intro</td>
<td class=odd>User</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>22violins__ste SRv4</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>P5 SuperLeadUnison BR</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>16:3</td>
<td>Ethiopia</td>
<td>User</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>22violins__ste SRv4</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>P5 SuperLeadUnison BR</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>16:4</td>
<td class=odd>Tutu</td>
<td class=odd>Acoustic</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Tutu Hit</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>hanon-spike fab BRASS</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>17:1</td>
<td>Mk I Deep</td>
<td>EPiano1</td>
<td>7</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>SynthPad2__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>17:2</td>
<td class=odd>Mk I Deep</td>
<td class=odd>EPiano1</td>
<td class=odd>7</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>SynthPad2__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>17:3</td>
<td>High Crime Tines</td>
<td>EPiano1</td>
<td>6</td>
<td>EP5 Bright Tines CustomMod 5.3</td>
<td>FullOrchestra__ste SRv4</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>DX7 II FullTines</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>17:4</td>
<td class=odd>Zappelbude Pad</td>
<td class=odd>Pad</td>
<td class=odd>7</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>17:5</td>
<td>Ohne dich    </td>
<td>Grand</td>
<td>7</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>18:1</td>
<td class=odd>Jungle</td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>Call of Nature</td>
<td class=odd>EP6 SparkleTop Vintage67 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>18:2</td>
<td>Surviver        </td>
<td>String</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>TR-Rack - ST Major O.Hit</td>
<td>Clavinet D6  5.0</td>
<td>OrchStrings Marc_LiteVib _ste KHv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>18:3</td>
<td class=odd>DiscoPnorings   </td>
<td class=odd>String</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>OrchStrings Marc_LiteVib _ste KHv4</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Pizzicato_OrchStr_ste KHv4</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>18:4</td>
<td>Aint Nobody     </td>
<td>Synth</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>Marimba__STv5</td>
<td>Clavinet D6  5.0</td>
<td>StringOrchestra_ _ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>18:5</td>
<td class=odd>Studio Grand</td>
<td class=odd>Grand</td>
<td class=odd>7</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>19:1</td>
<td>Im so excited</td>
<td>EPiano1</td>
<td>6</td>
<td>EPiano 4    Mk V ClosIdeal 5.3</td>
<td>FullStrings1__ste SRv4</td>
<td>Not found: (Unknown)</td>
<td>SynthPad2__ste CLv4</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>19:2</td>
<td class=odd>Super Saw       </td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>19:3</td>
<td>Town and Country</td>
<td>Grand</td>
<td>7</td>
<td>White Grand Sml 6.1</td>
<td>22violins__ste SRv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>FullStrings1__ste SRv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>19:4</td>
<td class=odd>Town and C Split</td>
<td class=odd>Organ</td>
<td class=odd>7</td>
<td class=odd>White Grand Sml 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>19:5</td>
<td>Pink Panther    </td>
<td>Pad</td>
<td>7</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>FullStrings1__ste SRv4</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>20:1</td>
<td class=odd>Fast Analog Pad</td>
<td class=odd>Grand</td>
<td class=odd>6</td>
<td class=odd>EPiano 4    Mk V ClosIdeal 5.3</td>
<td class=odd>Soft Analog</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>SynthPad1__ste CLv4</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>20:3</td>
<td>Soft Pad  MW</td>
<td>Pad</td>
<td>6</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td>Studio Grand 2 YaC7  Med 5.3</td>
<td>SynthPad1__ste CLv4</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>20:4</td>
<td class=odd>Geschichte Pad</td>
<td class=odd>Pad</td>
<td class=odd>6</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Studio Grand 2 YaC7  Med 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>20:5</td>
<td>MIDI   </td>
<td>None</td>
<td>7</td>
<td>Marimba  6.1</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
</table></html>
    `;