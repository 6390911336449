import React, {useState, useEffect, useMemo, useCallback, useContext} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import './App.css';
import Midi from "./util/Midi";
import Header from "./components/Header/Header";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createMuiTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from "./components/Sidebar/Sidebar";
import InstrumentList from "./components/InstrumentList/InstrumentList";
import InstrumentView from "./components/InstrumentView/InstrumentView";
import InstrumentSettings from "./components/InstrumentSettings/InstrumentSettings";
import ProjectListView from "./components/ProjectListView/ProjectListView"
import {ProjectView} from "./components/ProjectView/ProjectView"
import {ProjectStoreContext} from "./appstate/ProjectStore"
import {InstrumentStoreContext} from "./appstate/InstrumentStore"
import CollectPresets from "./components/CollectPresets/CollectPresets"

const log = (text) => {
    console.log('[console]', text);
};

/*
For theming see:

https://medium.com/@tylermalone/customizing-the-darkbasetheme-in-material-ui-react-components-7b8b596b6384

https://egghead.io/lessons/react-create-a-react-hook-to-toggle-between-light-and-dark-mode-inside-of-material-ui-themes

 */

const theme = createMuiTheme({
/*
    palette: {
        type: 'dark',
        secondary: {
            main: '#03DAC5'
        }
    }

 */
});

function App() {
    const [activeTab, setActiveTab] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        //Midi.initialize(LocalStorage.getMidiInput(), LocalStorage.getMidiOutput());
        Midi.initialize();
    }, []);

    const toggleSidebar = (state) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setSidebarOpen(state);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Router>
                    <Header onOpenSidebar={toggleSidebar(true)} />
                        <div style={{marginTop: 64, marginBottom: 56}}>
                                <Switch>
                                    <Route path="/" exact>
                                        <InstrumentList />
                                    </Route>
                                    <Route path="/instruments" exact>
                                        <InstrumentList />
                                    </Route>
                                    <Route path="/instruments/:instrumentId" exact>
                                        <InstrumentView />
                                    </Route>
                                    <Route path="/instruments/:instrumentId/configure" exact>
                                        <InstrumentSettings />
                                    </Route>
                                    <Route path="/instruments/:instrumentId/collect-presets" exact>
                                        <CollectPresets />
                                    </Route>
                                    <Route path="/projects" exact>
                                        <ProjectListView />
                                    </Route>
                                    <Route path="/projects/:projectId" exact>
                                        <ProjectView />
                                    </Route>
                                    <Route path="/settings" exact>
                                        <h2>Settings</h2>
                                    </Route>
                                </Switch>
                        </div>
                        {/*<Footer activeTab={activeTab} onTabChange={setActiveTab} />*/}
                        <Sidebar open={sidebarOpen} onDismiss={toggleSidebar} />
                    <CssBaseline />
                </Router>
            </ThemeProvider>
        </>
    )
}

export default App;

/*

[{"programNumber":0,"bankMSB":0,"bankLSB":0,"name":"White Piano"},{"programNumber":16,"bankMSB":0,"bankLSB":0,"name":"Wurlitzer 2"},{"programNumber":20,"bankMSB":0,"bankLSB":0,"name":"Royal Pad"},{"programNumber":21,"bankMSB":0,"bankLSB":0,"name":"Slick Mk II"},{"programNumber":22,"bankMSB":0,"bankLSB":0,"name":"Pipes MW"},{"programNumber":23,"bankMSB":0,"bankLSB":0,"name":"Mellow Guit Vibes"},{"programNumber":24,"bankMSB":0,"bankLSB":0,"name":"Warm Winds"},{"programNumber":25,"bankMSB":0,"bankLSB":0,"name":"Spires MW"},{"programNumber":29,"bankMSB":0,"bankLSB":0,"name":"Filter Clav AT"},{"programNumber":1,"bankMSB":0,"bankLSB":1,"name":"Mellow Pad"},{"programNumber":25,"bankMSB":0,"bankLSB":1,"name":"Paradise Pad"},{"programNumber":50,"bankMSB":0,"bankLSB":1,"name":"Str Piano Flute"},{"programNumber":60,"bankMSB":0,"bankLSB":2,"name":"Dreams Organ 2"}]


 */