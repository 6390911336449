import React, {useEffect, useImperativeHandle, useState, useRef, useCallback} from "react";
import PresetListPage from "./PresetListPage";
import PresetButton from "../PresetButton/PresetButton";
import {PresetPageContainer, PaginationButton} from "./styles";
import Pagination from "@material-ui/lab/Pagination";
import {observer} from "mobx-react-lite";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import FormGroup from "@material-ui/core/FormGroup"
import {Button} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import {getDeviceType} from "../../util/Misc"
import type {Preset} from "../../util/Presets"
import {useScrollDirection} from "react-use-scroll-direction"

function getPresetPageArray(presets: Preset[], presetsPerPage: number)
{
    return Array.from({ length: Math.ceil(presets.length / presetsPerPage)})
}

const PresetList = observer(({presets, selectedPreset, multiSelection, presetsPerPage, searchText, onPresetClick}, ref) => {
    const scrollboxRef = useRef();

    const [filteredPresets, setFilteredPresets] = useState(presets);
    const [pageIndex, setPageIndex] = useState(0);
    const [pages, setPages] = useState(getPresetPageArray(filteredPresets, presetsPerPage));

    const theme = useTheme();
    const mediaQueryMediumSize = useMediaQuery(theme.breakpoints.up('md'));

    const scrollToPreset = useCallback((preset) => {
        const newPageIndex = Math.max(0, Math.floor(filteredPresets.indexOf(preset) / presetsPerPage));
        //if (pageIndex !== newPageIndex) {
            setPageIndex(newPageIndex);
        //}
    }, [filteredPresets]);

    useImperativeHandle(ref, () => ({
        scrollToPreset: (preset) => {
            scrollToPreset(preset);
        },
        scrollToLastPreset: () => {
            scrollToPreset(presets.slice(-1)[0]);
        }
    }));

    useEffect(() => {
        setFilteredPresets(presets.filter((preset) => searchText.length === 0 || preset.name.toLowerCase().includes(searchText.toLowerCase())));
    }, [presets, searchText]);

    //const pageCount = Math.ceil(filteredPresets.length / presetsPerPage);
    //const presetsOnCurrentPage = filteredPresets.slice(pageIndex * presetsPerPage, (pageIndex + 1) * presetsPerPage);

    useEffect(() => {
        setPageIndex(0);
    }, [searchText]);

    useEffect(() => {
        if (selectedPreset) {
            scrollToPreset(selectedPreset);
        }
    }, [selectedPreset, scrollToPreset]);

    useEffect(() => {
        const newPages = getPresetPageArray(filteredPresets, presetsPerPage);
        setPages(newPages);

/*
        if (pageIndex > pages.length - 1) {
            setPageIndex(pages.length - 1);
        }
*/
        // Ben�tigt kein pages (was in den Dependencies stehen m�sste)
        setPageIndex(prevPageIndex => Math.min(prevPageIndex, newPages.length - 1));
    }, [filteredPresets, presetsPerPage]);

    useEffect(() => {
        const scrollBoxElement = scrollboxRef.current;
        const pageElement = scrollBoxElement?.children[pageIndex];

        if (pageElement) {
            scrollBoxElement.scrollTo({
                left: pageElement.offsetLeft,
                //behavior: "smooth",
            });
        }
        /*
        scrollboxRef.current?.children[pageIndex] && scrollboxRef.current.children[pageIndex].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest"
        });
        */
    }, [pageIndex]);

    useEffect(() => {
        const onScrollEnd = () => {
            const scrollBoxElement = scrollboxRef.current;
            const firstPageElement = scrollBoxElement?.children[pageIndex];
            const newPageIndex = Math.floor(scrollBoxElement.scrollLeft / firstPageElement.offsetWidth);

            if (newPageIndex !== pageIndex) {
                setPageIndex(newPageIndex);
            }
        };

        scrollboxRef.current.addEventListener("scrollend", onScrollEnd);

        return () => {
            scrollboxRef.current.removeEventListener("scrollend", onScrollEnd);
        };
    }, [pageIndex]); // TODO: Evtl. muss pageIndex in die Depencendies

    return (
        <>
            <PresetPageContainer ref={scrollboxRef}>
                {pages.map((_, i) => <PresetListPage presets={filteredPresets} pageIndex={i} key={i} presetsPerPage={presetsPerPage} selectedPreset={selectedPreset} multiSelection={multiSelection} onPresetClick={onPresetClick} />)}
                {/*presetsOnCurrentPage.map((preset, key) => (
                    <PresetButton key={key} preset={preset} isSelected={preset === selectedPreset} onClick={onPresetClick} />
                ))*/}
            </PresetPageContainer>

            <FormGroup row style={{ gap: '1rem', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" onClick={() => setPageIndex((currentPageIndex) => currentPageIndex - 1)} disabled={pageIndex <= 0}>Prev Page</Button>
                <Button variant="contained" color="primary" style={{ marginLeft: 'auto' }} onClick={() => setPageIndex((currentPageIndex) => currentPageIndex + 1)} disabled={pageIndex >= pages.length - 1}>Next Page</Button>
            </FormGroup>
        </>
    );
}, { forwardRef: true });

export default PresetList;