import WebMidi from "webmidi";
import {Preset, PresetHelper} from "./Presets";

export const MIDI_EVENT_TYPES = Object.freeze({
    PRESET_CHANGE: 'programChange',
});

class Midi {
    static isInitialized = false;
    static midiInput: WebMidi.Input = null;
    static midiOutput: WebMidi.Output = null;

    static lastReceivedPreset : Preset = {
        programNumber: 0,
        bankMSB: 0,
        bankLSB: 0,
        name: ''
    };

    static onReceivedPresetChange = () => {};

    static lastProgramChangeSentTime = 0;

    static initialize() {
        if (this.isInitialized) {
            return;
        }

        this.isInitialized = true;

        WebMidi.enable((err) => {
            if (err) {
                console.log("WebMidi could not be enabled!", err);
            } else {
                console.log("WebMidi enabled.");

                /*
                if (initialMidiInput) {
                    const inputByName = WebMidi.getInputByName(initialMidiInput);
                    if (inputByName) {
                        this.midiInput = inputByName;
                    }
                }

                if (initialMidiOutput) {
                    const outputByName = WebMidi.getOutputByName(initialMidiOutput);
                    if (outputByName) {
                        this.midiOutput = outputByName;
                    }
                }

                 */

                /*
                if (!this.midiInput) {
                    this.midiInput = WebMidi.inputs[4];
                }

                if (!this.midiOutput) {
                    this.midiOutput = WebMidi.outputs[4];
                }
                 */

                /*
                currentMidiOutput.sendProgramChange(0);
                currentMidiOutput.playNote(['C4', 'D#4', 'G4'], 1, {time: "+100", duration: 1000, velocity: 0.4});
                currentMidiOutput.sendProgramChange(8, 1,{time: "+1000"});
                currentMidiOutput.playNote(['C4', 'D#4', 'G4'], 1, {time: "+1100", duration: 1000, velocity: 0.4});
                 */

                //console.log('Listen to ' + this.midiInput?.name);
                //console.log('Current output is ' + this.midiOutput?.name);
                //this.addInputListeners();
            }
        }, true);
    }

    static addInputListeners() {
        if (!this.midiInput) return;

        this.midiInput.addListener('programchange', 'all', (e) => {
            if (Date.now() - this.lastProgramChangeSentTime < 100) {
                console.debug('MIDI: Ignored program change due to sent out preset within the last 100 ms.');
                return;
            }

            console.debug('MIDI: received program change');
            this.lastReceivedPreset.programNumber = e.value;

            window.dispatchEvent(new CustomEvent(MIDI_EVENT_TYPES.PRESET_CHANGE, { detail: {
                preset: this.lastReceivedPreset,
                midiInputName: this.midiInput.name
            } }));
        });

        this.midiInput.addListener('controlchange', 'all', (e) => {
            // Bank Select MSB
            if (e.controller.number === 0) {
                this.lastReceivedPreset.bankMSB = e.value;
            }

            // Bank Select LSB
            if (e.controller.number === 32) {
                this.lastReceivedPreset.bankLSB = e.value;
            }
        });

        this.midiInput.addListener("sysex", "all", function (e) {
            console.log(e.data);
        });
    }

    static isMidiSupported() {
        return WebMidi.supported;
    }

    static sendPresetToCurrentOutput(preset: Preset) {
        if (!this.midiOutput)
            return;

        const isEmpty = (value) => value === undefined || value === null;
        !isEmpty(preset.bankMSB) && this.midiOutput.sendControlChange('bankselectcoarse', preset.bankMSB, 1);
        !isEmpty(preset.bankLSB) && this.midiOutput.sendControlChange('bankselectfine', preset.bankLSB, 1);
        !isEmpty(preset.programNumber) && this.midiOutput.sendProgramChange(preset.programNumber);

        this.lastProgramChangeSentTime = Date.now();

        // Das funktioniert noch nicht. Zumindest reagieren die Views (InstrumentVIew / ProjectVIew noch nicht)
        const instrument = preset.instrument;
        if (instrument) {
            const originalPreset = instrument.presets.find(p => PresetHelper.arePresetsEqual(p, preset));
            if (originalPreset) {
                instrument.activePreset = originalPreset;
                console.log('setze active preset');
            }
        }
    }

    static getMidiInputs() {
        return WebMidi.inputs;
    }

    static getMidiOutputs() {
        return WebMidi.outputs;
    }

    static changeMidiInput(inputName: string) {
        if (!WebMidi.enabled) {
            console.log('WebMidi is not ready - can\'t change MidiInput.');
            return;
        }

        if (this.midiInput) {
            this.midiInput.removeListener();
        }

        this.midiInput = WebMidi.getInputByName(inputName) || null;
        if (this.midiInput) {
            this.addInputListeners();
            console.debug('Added input listener for MIDI input ' + inputName);
        } else {
            console.debug('No MIDI input device found for name ' + inputName + '. Did not connect.');
        }
    }

    static changeMidiOutput(outputName: string) {
        if (!WebMidi.enabled) {
            console.log('WebMidi is not ready - can\'t change MidiInput.');
            return;
        }

        this.midiOutput = WebMidi.getOutputByName(outputName) || null;
    }
}

export default Midi;