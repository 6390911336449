import React from "react";
import {ButtonWrapper} from "./styles";
import Button from "@material-ui/core/Button";
import { withStyles} from "@material-ui/core";
import {observer} from "mobx-react-lite";

const styles = theme => ({
    Button: {
        height: 40,
        borderRadius: 7,
        backgroundColor: '02206f',
        color: 'white',
        fontSize: '1.3rem',
        lineHeight: '1.2',
        textTransform: 'none',
        overflow: 'hidden'
    },
    selected: {
        backgroundColor: '#ffca04',
        color: 'black',
        '&:hover': {
            backgroundColor: '#ffca04',
        }
    },
    multiSelected: {
        backgroundColor: '#0b7e31',
        color: 'white',
        '&:hover': {
            backgroundColor: '#0b7e31',
        }
    },
});

function is_touch_device() {
    return !!('ontouchstart' in window        // works on most browsers
        || navigator.maxTouchPoints);       // works on IE10/11 and Surface
}

const triggerByTouchStart = true;

const PresetButton = observer(({preset, isSelected, isMultiSelected, onClick, classes}) => (
    <Button
        variant="contained"
        color="primary"
        className={`${classes.Button} ${isSelected && classes.selected} ${isMultiSelected && classes.multiSelected}`}
        disableTouchRipple
        disableFocusRipple
        /*...((!triggerByTouchStart || !is_touch_device()) && {onMouseDown: (e) => onClick(e, preset)})*/
        /*...(triggerByTouchStart && {onTouchStart: (e) => onClick(e, preset)})*/
        onClick={(e) => onClick(e, preset)}
    >
        {preset.name}
    </Button>
));

export default withStyles(styles)(PresetButton);