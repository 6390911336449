import React, {useContext, useEffect} from 'react';

import { observer } from "mobx-react-lite";
import {InstrumentStore} from "../../appstate/InstrumentStore"
import { InstrumentStoreContext } from "../../appstate/InstrumentStore";
import { ProjectStoreContext } from "../../appstate/ProjectStore"

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Paper from '@material-ui/core/Paper';
import keyboardDefaultImage from './image/keyboard-default.jpg';
import Instrument from "./Instrument";
import prompt from "../Dialogs/TextInputDialog";
import getSlug from "speakingurl";

const handleNewInstrument = async (instrumentStore: InstrumentStore) => {
    const name = await prompt('New instrument', 'Enter instrument name', '');
    if (name) {
        const newInstrument = {
            id: instrumentStore.getUniqueSlugForName(name),
            name: name,
            image: keyboardDefaultImage,
            presets: [],
        };

        instrumentStore.instruments.push(newInstrument);
    }
};

export const InstrumentList = observer(({props}) => {
    const instrumentStore = useContext(InstrumentStoreContext);
    const projectStore = useContext(ProjectStoreContext);

    useEffect(() => {
        instrumentStore.persistInstruments();
        projectStore.persistProjects();
    }, []);

    return (
        <div>
            <Fab color="primary" size="small" style={{margin: 24}}>
                <AddIcon onClick={() => handleNewInstrument(instrumentStore)} />
            </Fab>

            <Grid
                container
                spacing={3}
                style={{width: '100%', padding: 24}}
            >
                {instrumentStore.instruments.map(instrument => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={instrument.name}>
                        <Instrument instrument={instrument} />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
});

export default InstrumentList;