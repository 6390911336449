import {decorate, observable} from "mobx";
import type {Instrument} from "../appstate/InstrumentStore"

export type Preset = {
    programNumber: number,
    bankMSB: number,
    bankLSB: number,
    name: string,
    instrument?: Instrument
};

/*
export class ThePreset {
    programNumber: number;
    bankMSB: number;
    bankLSB: number;
    name: string;

    constructor({ vars }) {
        this.programNumber = vars.programNumber;
        this.bankMSB = vars.bankMSB;
        this.bankLSB = vars.bankLSB;
        this.name = vars.name;
    }
}

decorate(ThePreset, {
    programNumber: observable,
    bankMSB: observable,
    bankLSB: observable,
    name: observable
});

 */

export const manufacturers = {
    YAMAHA: "YAMAHA",
    ROLAND: "ROLAND",
    KORG: "KORG",
    CLAVIA: "CLAVIA"
};

type Manufacturer = $Keys<typeof manufacturers>;

export class PresetHelper {
    static arePresetsEqual = (preset1: Preset, preset2: Preset) => (
        preset1.programNumber === preset2.programNumber &&
        preset1.bankMSB === preset2.bankMSB &&
        preset1.bankLSB === preset2.bankLSB
    );

    static getBankFromPresetByManufacturer(preset: Preset, manufacturer: Manufacturer) {
        if (manufacturer === manufacturers.CLAVIA)
           return preset.bankLSB;

        if (manufacturer === manufacturers.YAMAHA)
            return preset.bankLSB;

        return preset.bankLSB;
    }
}