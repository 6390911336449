import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {store as projectStore} from "./appstate/ProjectStore"
import {store as instrumentStore} from "./appstate/InstrumentStore"

instrumentStore.loadInstrumentsFromLocaleStorage();
projectStore.loadProjectsFromLocaleStorage(instrumentStore);

if (projectStore.projects.length > 0) {
    projectStore.defaultProject = projectStore.projects[0];
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
