import React from 'react';
import PropTypes from 'prop-types';

import { confirmable, createConfirmation } from 'react-confirm';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";

const AlertDialog = ({show, proceed, title = '', text = '', options}) => {
    return (
        <Dialog
            open={show}
            onClose={() => proceed(true)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={options && options.fullWidth}
            maxWidth="sm"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => proceed(true)} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AlertDialog.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
    options: PropTypes.object
};

const alert = createConfirmation(confirmable(AlertDialog));

export default function alertWrapper(title, text, options = {}) {
    if (title && !text) {
        text = title;
        title = undefined;
    }

    /*
    if (text.length < 10){
        options.fullWidth = true;
    }
     */

    return alert({title, text, options});
};