import styled, {css} from 'styled-components';

export const theme = {
    lightBlue: 'cornflowerblue'
};

export const noselect = css`
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */   
`;

export const Title = styled.h1`
    font-size: 1.5em;
    text-align: center;
    color: ${theme.lightBlue};

/*    
    width: calc(viewport-size);
    padding: 0.25em;
    background-color: #000000;
        
 */
`;

export const ButtonBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1.5em;
    
    /*
    width: 100%;
    padding-top: 0.5em;
    background-color: #000000;
    */
     
    button {
        margin-bottom: 1rem;
        min-width: 100px;
    }
    button + button {
        margin-left: 0.5em;
    }
`;

export const PageNumber = styled.div`
    min-width: 140px;
    font-size: 1.5em;
`;

export const NavButtonBar = styled(ButtonBar)`
    button {
        min-width: 120px;
    }
`;

export const Button = styled.button`
    border-radius: 5px;
    ${noselect}
`;

export const ToggleButton = styled(Button)`
    background-color: ${props => props.checked ? '#007127' : '#ffffff'};
`;

export const TextField = styled.input.attrs((/* props */) => ({
    type: 'text'
}))`
    font-size: 1em;
    width: 150px;
    height: 33px;
    padding: 0.2em;
    text-align: center;
    
    button + &, & + & {
        margin-left: 0.5em;
    }    
`;

export const NoMidiWarning = styled.p`
    color: #c70000;
`;

export const Spacer = styled.div`
    height: ${props => props.height};
`;