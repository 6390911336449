export const html = `
<html>
<style>
table { border: no; border-collapse: collapse; }
td { font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif; padding: 5pt; font-size: 12px; }
th { font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif; padding: 5pt; font-size: 12px; }
td.odd { background-color: #d5d5d5; color: black; }
</style><table>
<tr>
<th>Partition</td>
<th>Bank</td>
<th>Location</td>
<th>Name</td>
<th>Category</td>
<th>Version</td>
<th>Piano A</td>
<th>Samp Lib A</td>
<th>Piano B</td>
<th>Samp Lib B</td>
<th>Info</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>11</td>
<td class=odd>White Grand</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>12</td>
<td>Royal Grand 3D</td>
<td>Grand</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>13</td>
<td class=odd>Grand Imperial</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>14</td>
<td>Italian Grand MW</td>
<td>Grand</td>
<td>3.04</td>
<td>Grand Imperial Bdorf Lrg 5.3</td>
<td>(No sound)</td>
<td>Grand Imperial Bdorf Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>15</td>
<td class=odd>Glowing Pianopad</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>10-Celli Legato_SO 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>21</td>
<td>Velvet Grand</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>22</td>
<td class=odd>Grand Lady D</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Grand Lady D   Stw D  Lrg 5.3</td>
<td class=odd>22-Violins Leg LoVib_SR 3.0</td>
<td class=odd>Grand Lady D   Stw D  Lrg 5.3</td>
<td class=odd>22-Violins Leg LoVib_SR 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>23</td>
<td>Sparkletop EP 6</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>24</td>
<td class=odd>Nefertiti Mk I</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>25</td>
<td>Nefertiti Mk I 2</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>31</td>
<td class=odd>Wurlitzer 2</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>32</td>
<td>Bambino Upright</td>
<td>Upright</td>
<td>3.04</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>33</td>
<td class=odd>Grand Upright</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Grand Upright YaU3 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Grand Upright YaU3 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>34</td>
<td>Royal Pad MW</td>
<td>Acoustic</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>35</td>
<td class=odd>Filter Clav AT</td>
<td class=odd>Clavinet</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>41</td>
<td>DX7 Full Tines</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>42</td>
<td class=odd>JD800 Crystal</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>JD800 E.Piano Crystal Lrg5.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>43</td>
<td>MKS20 Piano</td>
<td>EPiano2</td>
<td>3.04</td>
<td>MKS20 A.Piano1 Lrg 5.1</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>44</td>
<td class=odd>MKS20 E-Piano 1</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>MKS20 E.Piano1 Lrg 5.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>45</td>
<td>MKS20 E-Piano 2</td>
<td>EPiano2</td>
<td>3.04</td>
<td>MKS20 E.Piano2 Lrg 5.1</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>51</td>
<td class=odd>MKS80 Piano</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>MK80 A.Piano1 Lrg 5.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>52</td>
<td>MKS80 E-Piano 1</td>
<td>EPiano2</td>
<td>3.04</td>
<td>MK80 E.Piano1 Contemp Lrg 5.1</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>53</td>
<td class=odd>Ballad EP 1</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>Ballad EP1  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank A</td>
<td>54</td>
<td>Ballad EP 2</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Ballad EP2  Sml 5.2</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank A</td>
<td class=odd>55</td>
<td class=odd>Hybrid LA Piano</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>Hybrid EP  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>11</td>
<td>Dynamic Strings</td>
<td>Acoustic</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Soft Strings Legato_SR 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>StudioStrings Leg Vib_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>12</td>
<td class=odd>B3 Full 2</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>13</td>
<td>B3 Praise MW</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>14</td>
<td class=odd>B3 Full</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>15</td>
<td>B3 Jazzy Joey</td>
<td>Acoustic</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>21</td>
<td class=odd>B3 Saw Wheel</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>22</td>
<td>Danceflr Peak MW</td>
<td>Synth</td>
<td>3.04</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>23</td>
<td class=odd>Warm Winds</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Flutes + Clarinets Leg_SR 3.1</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>25</td>
<td>Pipes MW</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>31</td>
<td class=odd>Synth Brass MW</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>DigiGrand 2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>DigiGrand 2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>32</td>
<td>Airy Saws MW</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>33</td>
<td class=odd>96 Tears Vox</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>34</td>
<td>16 8 2 Farfisa</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>35</td>
<td class=odd>Brass Dynamics</td>
<td class=odd>Acoustic</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>BrassSection Double 2_CL 3.0</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>Soul Horn Trio_CL 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>41</td>
<td>Spires MW</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>42</td>
<td class=odd>Blue Rose</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Rock Guitar Mellotron_MKII Sml mono 3.0</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Slow Vibra Mellotron_M300 Sml mono 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>43</td>
<td>B3 Cocktail</td>
<td>Acoustic</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Contra Bass Plucked_ST mono 3.1</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Privilege Flute_PS mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>44</td>
<td class=odd>Southern Cross</td>
<td class=odd>Pluck</td>
<td class=odd>3.04</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>Ukrainian Bandura_PS mono 3.1</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>45</td>
<td>Hybrid Orch MW</td>
<td>Wind</td>
<td>3.04</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>All Winds Legato_SR 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>51</td>
<td class=odd>Instant Calypso</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>52</td>
<td>Balladeer</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Ballad EP1  Sml 5.2</td>
<td>(No sound)</td>
<td>Ballad EP1  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>53</td>
<td class=odd>Small Ensemble</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>Ital Harpsich 1B Long Stri 5.0</td>
<td class=odd>Flutes + Clarinets Leg_SR 3.1</td>
<td class=odd>Ital Harpsich 1B Long Stri 5.0</td>
<td class=odd>String Quartet Leg Vib_SR mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank B</td>
<td>54</td>
<td>Dlx Stage Keys</td>
<td>Grand</td>
<td>3.04</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank B</td>
<td class=odd>55</td>
<td class=odd>Tight Tines MW</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>11</td>
<td>Nordic Overland</td>
<td>Fantasy</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>12</td>
<td class=odd>CP80 Soft Pad</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>(No sound)</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>13</td>
<td>All Those Days</td>
<td>Upright</td>
<td>3.04</td>
<td>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td>(No sound)</td>
<td>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>14</td>
<td class=odd>Pure Pipes</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>15</td>
<td>UniSaw Layer MW</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>21</td>
<td class=odd>Pizzicato Mix MW</td>
<td class=odd>String</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>OrchStrings Pizzicato_KH 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>StudioStrings Pizzicato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>22</td>
<td>Stockholm  EP 9</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP9 Stockholm XL 6.0</td>
<td>(No sound)</td>
<td>EP9 Stockholm XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>23</td>
<td class=odd>B3 Indigo</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Saloon Upright Baumgardt Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Saloon Upright Baumgardt Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>24</td>
<td>Nice Bones</td>
<td>Acoustic</td>
<td>3.04</td>
<td>BlueSwede Ostl&Alm Lrg 5.3</td>
<td>Brass Ensemble 2_KG 3.1</td>
<td>BlueSwede Ostl&Alm Lrg 5.3</td>
<td>Trombones Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>25</td>
<td class=odd>Static EP Wheel</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>31</td>
<td>Bright EQ Panels</td>
<td>Grand</td>
<td>3.04</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>32</td>
<td class=odd>Blue Chamber MW</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>33</td>
<td>Event Horizon MW</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>34</td>
<td class=odd>Gentle Sync Splt</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>35</td>
<td>Soul Horns MW</td>
<td>Wind</td>
<td>3.04</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>Soul Horn Trio_CL 3.1</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>BrassSection Double 2_CL 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>41</td>
<td class=odd>Velvet Silver</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>42</td>
<td>Secret People MW</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>Fend Jazz Fing Preamp_ST mono 3.0</td>
<td>Clavinet D6  5.0</td>
<td>Quena Latin Flute_BR mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>43</td>
<td class=odd>Mk II Bass Flute</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP3 Shallow Close Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Celtic Whistle_PS mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>44</td>
<td>Digital Glue</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>45</td>
<td class=odd>Lush PWM Pad MW</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>51</td>
<td>Silver Grand</td>
<td>Grand</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>52</td>
<td class=odd>Bittersweet MW</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank C</td>
<td>53</td>
<td>Clav B3 Rotary</td>
<td>Organ</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank C</td>
<td class=odd>55</td>
<td class=odd>Lost Temple MW</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Hybrid EP  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>Hybrid EP  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>11</td>
<td>Grand DX Wheel</td>
<td>Grand</td>
<td>3.04</td>
<td>Grand Imperial Bdorf Lrg 5.3</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>12</td>
<td class=odd>Goblins AT</td>
<td class=odd>Harpsi</td>
<td class=odd>3.04</td>
<td class=odd>French Harpsi 1D Lo8 Lo4 5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>13</td>
<td>B3 Smeary Bro</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Privilege Flute Vibrato_PS mono 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Privilege Flute Vibrato_PS mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>14</td>
<td class=odd>Formant SuperArp</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>15</td>
<td>Factory Rituals</td>
<td>FX</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>Toy Piano_DV stereo 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Mbira_PS 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>21</td>
<td class=odd>Vinyl Prog Pnls</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Grand Lady D   Stw D  Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Grand Lady D   Stw D  Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>22</td>
<td>EP Detune Layer</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>23</td>
<td class=odd>B3 Booker</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Nylon Guitar_DV mono 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Privilege Flute Vibrato_PS mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>25</td>
<td>Fun Split</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td>Ballad EP2  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>31</td>
<td class=odd>Imperial Bell MW</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>Belltower Gefle_PS 3.0</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>Small Chimes_ST 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>32</td>
<td>Digital Age MW</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>DigiGrand 1  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>33</td>
<td class=odd>Neue Kirche</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>34</td>
<td>Gothenburg Funk</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>35</td>
<td class=odd>Dirty EP Horns</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>Soul Horn Trio_CL 3.1</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>41</td>
<td>Heartache CP80</td>
<td>Acoustic</td>
<td>3.04</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>42</td>
<td class=odd>Piano Thumbs</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Grand Upright YaU3 Lrg 5.4</td>
<td class=odd>Mbira_PS 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>43</td>
<td>B3 Gospel Split</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>44</td>
<td class=odd>Analog Honesty</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>45</td>
<td>Air Organ Wheel</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>51</td>
<td class=odd>Royal DynStrings</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Soft Strings Legato_SR 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>StudioStrings Marc Vib_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>52</td>
<td>Clavinet DA</td>
<td>Clavinet</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank D</td>
<td class=odd>53</td>
<td class=odd>Duo And Rotary</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank D</td>
<td>54</td>
<td>Furious AT Lead</td>
<td>Lead</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>11</td>
<td class=odd>Velvet EP Wheel</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>12</td>
<td>Slapped Clav</td>
<td>Clavinet</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>13</td>
<td class=odd>Electrified MW</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Ballad EP2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>Hybrid EP  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>15</td>
<td>Big Filter Pad</td>
<td>Pad</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>21</td>
<td class=odd>Super Grand</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>DigiGrand 2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>22</td>
<td>Chick Trick</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>Bolivian Panpipe_PS mono 3.1</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>23</td>
<td class=odd>B3 Easy Split</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>24</td>
<td>Hera PWM MW</td>
<td>Synth</td>
<td>3.04</td>
<td>DigiGrand 1  Sml 5.2</td>
<td>(No sound)</td>
<td>DigiGrand 1  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>25</td>
<td class=odd>Feedbk Split AT</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>31</td>
<td>Quicksilver MW</td>
<td>Grand</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>32</td>
<td class=odd>Mellow AT</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>10-Celli Legato_SO 3.1</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>10-Celli Legato_SO 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>33</td>
<td>Piano Layers MW</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Hybrid EP  Sml 5.2</td>
<td>Fend Prec Direct_ST mono 3.0</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>34</td>
<td class=odd>Flanged EP Split</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>Contra Bass Plucked_ST mono 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>35</td>
<td>Nylon Santoor AT</td>
<td>Pluck</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Nylon Guitar_DV mono 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Indian Santoor_PS 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>41</td>
<td class=odd>Jazz Tapes</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>Double Bass Chamberlin_UU Sml mono 3.0</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>OpenTrumpet Chamberlin_M35 Sml mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>42</td>
<td>Romantic Upright</td>
<td>Upright</td>
<td>3.04</td>
<td>BlueSwede Ostl&Alm Lrg 5.3</td>
<td>(No sound)</td>
<td>BlueSwede Ostl&Alm Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>43</td>
<td class=odd>B3 Bad Friends</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>44</td>
<td>Nautilus MW</td>
<td>Fantasy</td>
<td>3.04</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>45</td>
<td class=odd>Two Pad Split</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>51</td>
<td>Sneaky Splits</td>
<td>Grand</td>
<td>3.04</td>
<td>Grand Imperial Bdorf Lrg 5.3</td>
<td>3-Bassoons Legato_SO 3.0</td>
<td>Grand Imperial Bdorf Lrg 5.3</td>
<td>StudioStrings Pizzicato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>52</td>
<td class=odd>CP Mk V Fade MW</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Overland Guitar_PS mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>53</td>
<td>B3 Old Style</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank E</td>
<td class=odd>54</td>
<td class=odd>Mass In Any Key</td>
<td class=odd>Harpsi</td>
<td class=odd>3.04</td>
<td class=odd>French Harpsi 1D Lo8 Lo4 5.0</td>
<td class=odd>Flutes + Oboe Legato_SR 3.1</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank E</td>
<td>55</td>
<td>LoFi-HiFi Pno MW</td>
<td>Grand</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>11</td>
<td class=odd>Mellow Pad</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>12</td>
<td>Basilicata AB</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Big Choir_SO 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Boys Choir Vib_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>13</td>
<td class=odd>Jingles</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>14</td>
<td>Setting Out MW</td>
<td>Synth</td>
<td>3.04</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>Bassoon Chamberlin_M25 Sml mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>15</td>
<td class=odd>1955 Recording</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>21</td>
<td>Rain Piano</td>
<td>Upright</td>
<td>3.04</td>
<td>Rain Piano SvPnoFab Lrg 5.3</td>
<td>(No sound)</td>
<td>Rain Piano SvPnoFab Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>22</td>
<td class=odd>Pipe Wheel</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>23</td>
<td>Smooth Lines</td>
<td>Lead</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>24</td>
<td class=odd>Electric Finger</td>
<td class=odd>Bass</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>25</td>
<td>Slick Grand</td>
<td>Grand</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>31</td>
<td class=odd>Smoky Rotors</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>Trombone Chamberlin_MMaster Sml mono 3.0</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>32</td>
<td>Hybrid Pipes MW</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>33</td>
<td class=odd>In The Woods MW</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>DigiGrand 2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>DigiGrand 2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>34</td>
<td>Clav Synth</td>
<td>Clavinet</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>35</td>
<td class=odd>Royal Strings</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>ChamberStr Leg HiVib_KH 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>10-Celli Legato_SO 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>41</td>
<td>Wurlie 2000</td>
<td>EPiano1</td>
<td>3.04</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>42</td>
<td class=odd>B3 Soft Split</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>43</td>
<td>Analog Jam MW</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>44</td>
<td class=odd>Silk Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Small Choir Male ah Soft_CL 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>45</td>
<td>CP AnaStrings</td>
<td>Grand</td>
<td>3.04</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>Eminent 310 Str FullReg_PH mono 3.0</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>ChamberStr Leg HiVib_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>51</td>
<td class=odd>Top Of Morning</td>
<td class=odd>Arpeggio</td>
<td class=odd>3.04</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>52</td>
<td>96 Tears Farfisa</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>53</td>
<td class=odd>Filter Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank F</td>
<td>54</td>
<td>Nosferatu 2017</td>
<td>FX</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>French Harpsi 1D Lo8 Lo4 5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank F</td>
<td class=odd>55</td>
<td class=odd>Paradise Pad</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Small Choir Fem ah Soft_CL 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>11</td>
<td>Shiny Tines</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td>EP5 BrightTines XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>12</td>
<td class=odd>Pipe Organs MW</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>13</td>
<td>Chubby Keys</td>
<td>Synth</td>
<td>3.04</td>
<td>EP5 BrightTines XL 6.0</td>
<td>(No sound)</td>
<td>EP5 BrightTines XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>14</td>
<td class=odd>Amped Duo</td>
<td class=odd>Pluck</td>
<td class=odd>3.04</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>Les Paul Neck-mic_SW mono 3.0</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>Les Paul Neck-mic_SW mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>15</td>
<td>Velvet MW Pad</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>23</td>
<td class=odd>Soft 3D Organ</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>24</td>
<td>Party Saw Filter</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>25</td>
<td class=odd>Pad N Lead</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>31</td>
<td>Bright Layer</td>
<td>Synth</td>
<td>3.04</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>32</td>
<td class=odd>DX FullTines</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>33</td>
<td>DC5 Vox</td>
<td>Organ</td>
<td>3.04</td>
<td>Wurlitzer 1 200A 5.3</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>34</td>
<td class=odd>Mello Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>16 Violin Mellotron_M400 Sml Stereo 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>MixCustChoirMellotron_M400 Sml mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>35</td>
<td>Bass Guitar Splt</td>
<td>Pluck</td>
<td>3.04</td>
<td>EP4 Mk5 80s Lrg 6.0</td>
<td>Fend Prec Preamp_ST mono 3.0</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>Les Paul Neck-mic_SW mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>41</td>
<td class=odd>CP Grand MW</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>42</td>
<td>Detuned Pnos</td>
<td>Upright</td>
<td>3.04</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>43</td>
<td class=odd>Steam Wheel</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>45</td>
<td>Split Machines</td>
<td>String</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Logan Str Melody 8+4_PH mono 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Farf Polychrome Str 8+4_BR mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>51</td>
<td class=odd>Crunchy Grand</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>10-Celli Legato_SO 3.1</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>52</td>
<td>Layer Piano</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Hybrid EP  Sml 5.2</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>53</td>
<td class=odd>Fast Samba</td>
<td class=odd>Acoustic</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Acoustic Piano 3_KG mono 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Brass Section Pop 2_BR 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank G</td>
<td>54</td>
<td>Buildup Pianohit</td>
<td>FX</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Mbira_PS 3.0</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank G</td>
<td class=odd>55</td>
<td class=odd>Hip Setup Split</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>12</td>
<td>Mad Bass Split</td>
<td>Bass</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>OrchStrings Legato_KH 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>13</td>
<td class=odd>Velocity Park</td>
<td class=odd>Pluck</td>
<td class=odd>3.04</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>Indian Surmantal_PS 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Indian Surmantal_PS 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>14</td>
<td>Reverse Lead</td>
<td>Lead</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>15</td>
<td class=odd>Bell Pond</td>
<td class=odd>Fantasy</td>
<td class=odd>3.04</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>Small Bells_SO 3.0</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>Metallophone_DV 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>21</td>
<td>DryWet Grands AT</td>
<td>Grand</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>22</td>
<td class=odd>Smooth Wave MW</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>Flutes + Clarinets Leg_SR 3.1</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>Flutes + Clarinets Leg_SR 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>23</td>
<td>Dynamic Drawbars</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>24</td>
<td class=odd>Attic M Ensemble</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>25</td>
<td>For 1 Musician</td>
<td>FX</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Vibes_DV mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>31</td>
<td class=odd>D Wheel Pad</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>32</td>
<td>Tines Harmoni MW</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP5 BrightTines XL 6.0</td>
<td>Harmonica_DV mono 3.1</td>
<td>Ballad EP2  Sml 5.2</td>
<td>Melodica Fast_PS mono 3.1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>33</td>
<td class=odd>Saw Pipes</td>
<td class=odd>Organ</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>34</td>
<td>Pad Tape</td>
<td>Pad</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>MixCustChoirMellotron_M400 Sml mono 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>3 Violins Chamberlin_MMaster Sml mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>35</td>
<td class=odd>Harp Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Lute Harp Soft_PS 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>41</td>
<td>Velvet Soft MW</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>Rain Piano SvPnoFab Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>42</td>
<td class=odd>Blue Mk II</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP3 Shallow Close Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>43</td>
<td>Pale B3 Trumpet</td>
<td>Organ</td>
<td>3.04</td>
<td>EP7 Amped Tines Med 6.0</td>
<td>OpenTrumpet Chamberlin_M35 Sml mono 3.0</td>
<td>EP7 Amped Tines Med 6.0</td>
<td>OpenTrumpet Chamberlin_M35 Sml mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>44</td>
<td class=odd>Rabbit Hole AT</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Angelic Choir6 Oh_PS 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>45</td>
<td>HybridStrings MW</td>
<td>String</td>
<td>3.04</td>
<td>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td>(No sound)</td>
<td>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>51</td>
<td class=odd>Fictious Dance</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Saloon Upright Baumgardt Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Indian Surmantal_PS 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>52</td>
<td>Twin Players</td>
<td>Grand</td>
<td>3.04</td>
<td>EP3 Shallow Close Lrg 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>10-Celli Legato_SO 3.1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>53</td>
<td class=odd>Birch Church</td>
<td class=odd>Acoustic</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Organ Flute 3_DV 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank H</td>
<td>54</td>
<td>Modest Keys</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank H</td>
<td class=odd>55</td>
<td class=odd>Sweeping Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>Small Choir Fem oh_CL 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>11</td>
<td>Grand Meat</td>
<td>Grand</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Small Chimes_ST 3.0</td>
<td>White Grand XL 6.1</td>
<td>Belltower Gefle_PS 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>12</td>
<td class=odd>Cranking Keys MW</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>13</td>
<td>B3 Jazz</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>14</td>
<td class=odd>Tomita Str MW</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>15</td>
<td>Bass Pad Split</td>
<td>Pad</td>
<td>3.04</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td>Grand Lady D   Stw D  Lrg 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>21</td>
<td class=odd>Royal Tines MW</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>22</td>
<td>Funkatronix</td>
<td>Clavinet</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>23</td>
<td class=odd>Sparkle Top MW</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>24</td>
<td>Blockbuster</td>
<td>FX</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>Orchestral Brass_SR 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>25</td>
<td class=odd>Mini Lead</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>31</td>
<td>Stage Keys</td>
<td>Grand</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>32</td>
<td class=odd>Mellow Delay</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>String Quartet Leg Vib_SR mono 3.0</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>String Quartet Leg Vib_SR mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>33</td>
<td>Early 80s Panels</td>
<td>Synth</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>34</td>
<td class=odd>Screamer</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>35</td>
<td>Composers Seat</td>
<td>Wind</td>
<td>3.04</td>
<td>Grand Imperial Bdorf Lrg 5.3</td>
<td>Orchestral Brass_SR 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Trumpets Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>41</td>
<td class=odd>Grand EP HardPan</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>42</td>
<td>Piano Cake MW</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Ballad EP2  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>43</td>
<td class=odd>Mk V Tremolo AT</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Hohn StrMelody2_BR mono 3.0</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Hohn StrMelody2_BR mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>44</td>
<td>Uncompromise</td>
<td>Lead</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>45</td>
<td class=odd>Bright Star Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>51</td>
<td>Fast Metal Pad</td>
<td>Pad</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>Metallophone_DV 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>52</td>
<td class=odd>Daydreaming</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank I</td>
<td>53</td>
<td>Smooth Tune</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank I</td>
<td class=odd>55</td>
<td class=odd>Pianos Bowed M</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Studio Grand 2 YaC7  Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>11</td>
<td>Flute-Piano Solo</td>
<td>Wind</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Bolivian Panpipe_PS mono 3.1</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>12</td>
<td class=odd>Prepared Upright</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Grand Upright YaU3 Lrg 5.4</td>
<td class=odd>Mbira_PS 3.0</td>
<td class=odd>Saloon Upright Baumgardt Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>13</td>
<td>Bright Tines MW</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP5 BrightTines XL 6.0</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>14</td>
<td class=odd>Space Lament</td>
<td class=odd>FX</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>15</td>
<td>Complex Pad MW</td>
<td>Pad</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>21</td>
<td class=odd>Fortified EP</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>22</td>
<td>Wurly Delay MW</td>
<td>EPiano1</td>
<td>3.04</td>
<td>Wurlitzer 1 200A 5.3</td>
<td>(No sound)</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>23</td>
<td class=odd>B3 Smeary Jazz</td>
<td class=odd>Acoustic</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>24</td>
<td>Funeral March</td>
<td>Wind</td>
<td>3.04</td>
<td>Pearl Upright Lrg 6.0</td>
<td>Bass Trombone_SO 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Muted Trumpet 2_ST mono 3.1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>25</td>
<td class=odd>Hard Stereo Synt</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>31</td>
<td>Sunset Keys Ab</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>32</td>
<td class=odd>Bass Keys Split</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>33</td>
<td>Noise EP</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>34</td>
<td class=odd>Quick Smash Hits</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>35</td>
<td>Super Octave MW</td>
<td>Synth</td>
<td>3.04</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>41</td>
<td class=odd>Late 80s Summer</td>
<td class=odd>EPiano2</td>
<td class=odd>3.04</td>
<td class=odd>DigiGrand 1  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>42</td>
<td>EP Squares MW</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP4 Mk5 80s Lrg 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>43</td>
<td class=odd>Horns And Sax</td>
<td class=odd>Acoustic</td>
<td class=odd>3.04</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Soul Horn Trio_CL 3.1</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Tenor Saxophone_KG mono 3.0</td>
<td class=odd>WARNING: program is missing 2 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>44</td>
<td>Soft Strings MW</td>
<td>String</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Soft Strings Legato_SR 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Soft Strings Legato_SR 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>51</td>
<td class=odd>Wooden Love</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>NyckelHarpa_PS 3.1</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>52</td>
<td>Hybrid Tines</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DigiGrand 2  Sml 5.2</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>53</td>
<td class=odd>Slick Groove</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Solo Cello 2 Vibrato_SR 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank J</td>
<td>54</td>
<td>200 Pounds</td>
<td>Synth</td>
<td>3.04</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank J</td>
<td class=odd>55</td>
<td class=odd>Super Brass</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>11</td>
<td>Swedish Jazz</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>Contra Bass Finger_BS mono 3.1</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Vibes_DV mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>12</td>
<td class=odd>Nefertiti Pad</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>13</td>
<td>B3 Samba Summer</td>
<td>Organ</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Nylon Guitar_DV mono 3.0</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>Privilege Flute Vibrato_PS mono 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>14</td>
<td class=odd>Winn n Wood</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>15</td>
<td>Pocket Groove</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>21</td>
<td class=odd>Power Piano</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Not found: (Unknown)</td>
<td class=odd>Celeste_ST mono 3.0</td>
<td class=odd>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>22</td>
<td>Orch Winds Split</td>
<td>Wind</td>
<td>3.04</td>
<td>Grand Lady D   Stw D  Lrg 5.3</td>
<td>Wind-String-Brass_SR 3.0</td>
<td>Grand Lady D   Stw D  Lrg 5.3</td>
<td>Flutes + Clarinets Leg_SR 3.1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>23</td>
<td class=odd>Slow Strings</td>
<td class=odd>String</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Slw_Str_VSynth</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>24</td>
<td>Reckless Lead</td>
<td>Lead</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>25</td>
<td class=odd>Beet It</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>31</td>
<td>Sacred Tines</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP5 BrightTines XL 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>32</td>
<td class=odd>Piano Memories</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>33</td>
<td>Rich Stage</td>
<td>Organ</td>
<td>3.04</td>
<td>EP5 BrightTines XL 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>34</td>
<td class=odd>5th Wheel Split</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>35</td>
<td>Synth Brass Stab</td>
<td>Wind</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>41</td>
<td class=odd>Blue Swede</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>42</td>
<td>Hard Stereo DX</td>
<td>EPiano2</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>43</td>
<td class=odd>Cosmo EP MW</td>
<td class=odd>Fantasy</td>
<td class=odd>3.04</td>
<td class=odd>EP3 Shallow Close Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>44</td>
<td>Submerging Pad</td>
<td>Pad</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>45</td>
<td class=odd>String Player</td>
<td class=odd>Arpeggio</td>
<td class=odd>3.04</td>
<td class=odd>Grand Imperial Bdorf Lrg 5.3</td>
<td class=odd>12 String Guitar1_DV mono 3.0</td>
<td class=odd>Saloon Upright Baumgardt Lrg 5.3</td>
<td class=odd>Fend Jazz Fing Preamp_ST mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>51</td>
<td>Weird Saloon</td>
<td>Upright</td>
<td>3.04</td>
<td>Pearl Upright Lrg 6.0</td>
<td>Small Choir Male oh_CL 3.0</td>
<td>EP3 Shallow Close Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank K</td>
<td class=odd>52</td>
<td class=odd>The Clockmaker</td>
<td class=odd>Upright</td>
<td class=odd>3.04</td>
<td class=odd>Saloon Upright Baumgardt Lrg 5.3</td>
<td class=odd>Celeste_ST mono 3.0</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank K</td>
<td>53</td>
<td>Morph Organ</td>
<td>Organ</td>
<td>3.04</td>
<td>Mellow Upright Bdrf C130 Lrg 5.4</td>
<td>(No sound)</td>
<td>EGrand 3 Mono CP80Amped Sml 5.3</td>
<td>16-Violins Pizzicato_SO 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank L</td>
<td class=odd>53</td>
<td class=odd>Davids Lead</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank L</td>
<td>54</td>
<td>Hybrid Brass</td>
<td>Wind</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>BrassSection Double 2_CL 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank L</td>
<td class=odd>55</td>
<td class=odd>The Pizz</td>
<td class=odd>String</td>
<td class=odd>3.04</td>
<td class=odd>French Harpsi 1D Lo8 Lo4 5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>French Harpsi 1D Lo8 Lo4 5.0</td>
<td class=odd>16-Violins Pizzicato_SO 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>11</td>
<td>Chromatic Perc</td>
<td>EPiano2</td>
<td>3.04</td>
<td>Marimba  6.1</td>
<td>(No sound)</td>
<td>Vibraphone  6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>12</td>
<td class=odd>Phaser Rhodes</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>13</td>
<td>ChorusMod Rhodes</td>
<td>Grand</td>
<td>3.04</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>14</td>
<td class=odd>Grunge Phaser Cl</td>
<td class=odd>Clavinet</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>15</td>
<td>Synth Brass St 2</td>
<td>Wind</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>21</td>
<td class=odd>Synth Brass St 3</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>22</td>
<td>Creepin Organ</td>
<td>Grand</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>23</td>
<td class=odd>Creepin Orgn Ful</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>24</td>
<td>Dreams Organ</td>
<td>Grand</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>31</td>
<td class=odd>Dreams Organ 2</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>32</td>
<td>Golden EP Pad MW</td>
<td>Grand</td>
<td>3.04</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td>Ballad EP2  Sml 5.2</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>33</td>
<td class=odd>Golden EP Hold</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Ballad EP2  Sml 5.2</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>34</td>
<td>Dreams Organ 3</td>
<td>Grand</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>35</td>
<td class=odd>Brass</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP2 Ideal Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>41</td>
<td>Brass 2</td>
<td>EPiano1</td>
<td>3.04</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>42</td>
<td class=odd>Spires MW 2</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>43</td>
<td>Nordic Overlan 2</td>
<td>Fantasy</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>44</td>
<td class=odd>Nordic Overlan 3</td>
<td class=odd>Fantasy</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>45</td>
<td>White Grand 2</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>LA Synth Guitar</td>
<td>White Grand XL 6.1</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>51</td>
<td class=odd>Royal Grand 3D 2</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>52</td>
<td>Funky Lead</td>
<td>Clavinet</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>EP7 Amped Tines Med 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>53</td>
<td class=odd>Fonky Clav</td>
<td class=odd>Clavinet</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank M</td>
<td>54</td>
<td>DuKe Vibes</td>
<td>Synth</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank M</td>
<td class=odd>55</td>
<td class=odd>White Grand 3</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>LA Synth Guitar</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>11</td>
<td>White Grand 4</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>LA Synth Guitar</td>
<td>White Grand XL 6.1</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>12</td>
<td class=odd>LA Pianoo</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP5 BrightTines XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>13</td>
<td>LA Pianoo 2</td>
<td>EPiano1</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>14</td>
<td class=odd>Mk V Drive Solo</td>
<td class=odd>EPiano1</td>
<td class=odd>3.04</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Hohn StrMelody2_BR mono 3.0</td>
<td class=odd>EP4 Mk5 80s Lrg 6.0</td>
<td class=odd>Hohn StrMelody2_BR mono 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>15</td>
<td>Pad Piano</td>
<td>Undefined</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>21</td>
<td class=odd>Church Bells</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>curch bell birds in back</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>22</td>
<td>Churchy 1</td>
<td>Wind</td>
<td>3.04</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>Soul Horn Trio_CL 3.1</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>BrassSection Double 2_CL 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>23</td>
<td class=odd>Churchy 2</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>Soul Horn Trio_CL 3.1</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>BrassSection Double 2_CL 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>24</td>
<td>Churchy 3</td>
<td>Wind</td>
<td>3.04</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>Soul Horn Trio_CL 3.1</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>BrassSection Double 2_CL 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>25</td>
<td class=odd>Churchy 4</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>Soul Horn Trio_CL 3.1</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>BrassSection Double 2_CL 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>31</td>
<td>Churchy Fluty</td>
<td>Wind</td>
<td>3.04</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>Soul Horn Trio_CL 3.1</td>
<td>Wurlitzer 2 Amped XL 5.3</td>
<td>BrassSection Double 2_CL 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>32</td>
<td class=odd>Churchy Oboey</td>
<td class=odd>Wind</td>
<td class=odd>3.04</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>Soul Horn Trio_CL 3.1</td>
<td class=odd>Wurlitzer 2 Amped XL 5.3</td>
<td class=odd>BrassSection Double 2_CL 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>33</td>
<td>Fathoms</td>
<td>Undefined</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>34</td>
<td class=odd>Spectral maj7 11</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>35</td>
<td>Still still</td>
<td>Undefined</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>Ukrainian Bandura_PS mono 3.1</td>
<td>Vibraphone  6.0</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>41</td>
<td class=odd>Spectral maj7 14</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>42</td>
<td>Spectral maj7 12</td>
<td>Undefined</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>43</td>
<td class=odd>Pad Piano 2</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>44</td>
<td>Spectral maj7 13</td>
<td>Undefined</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>45</td>
<td class=odd>Ohrfeige Woodwin</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Woodwind Cluster</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>51</td>
<td>Haunted House MW</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>Cathedral Organ 3_SR 3.0</td>
<td>White Grand XL 6.1</td>
<td>Female voices ah_SR 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank N</td>
<td class=odd>54</td>
<td class=odd>Walking Tempo</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank N</td>
<td>55</td>
<td>Unnamed</td>
<td>Undefined</td>
<td>3.04</td>
<td>EP6 Sparkletop XL 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>11</td>
<td class=odd>Some Years ago</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>12</td>
<td>Home Remedy</td>
<td>Wind</td>
<td>3.04</td>
<td>Grand Lady D   Stw D  Lrg 5.3</td>
<td>(No sound)</td>
<td>Grand Lady D   Stw D  Lrg 5.3</td>
<td>Flutes + Clarinets Leg_SR 3.1</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>13</td>
<td class=odd>UniSaw Layer M 2</td>
<td class=odd>Synth</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>14</td>
<td>Circus</td>
<td>Grand</td>
<td>3.04</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>OrchStrings Legato_KH 3.0</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>15</td>
<td class=odd>You mean the Wor</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Pearl Upright Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>21</td>
<td>I told you Uprig</td>
<td>Upright</td>
<td>3.04</td>
<td>BlueSwede Ostl&Alm Lrg 5.3</td>
<td>(No sound)</td>
<td>Pearl Upright Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>22</td>
<td class=odd>Transcendence</td>
<td class=odd>User</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>Transcendence</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>23</td>
<td>Artificial MW</td>
<td>Undefined</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>ArtDoom Chord Alles</td>
<td>White Grand XL 6.1</td>
<td>ArtDoom Chord Alles</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>24</td>
<td class=odd>Traum Tempo Intr</td>
<td class=odd>User</td>
<td class=odd>3.04</td>
<td class=odd>BlueSwede Ostl&Alm Lrg 5.3</td>
<td class=odd>Traum vom Mond Intro</td>
<td class=odd>Pearl Upright Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>25</td>
<td>Walking in Memph</td>
<td>Undefined</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>31</td>
<td class=odd>LIML Organ</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>32</td>
<td>Organ 1</td>
<td>Undefined</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>33</td>
<td class=odd>Organ loud</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>34</td>
<td>Organ loud EP</td>
<td>Undefined</td>
<td>3.04</td>
<td>EP2 Ideal Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>35</td>
<td class=odd>Corona</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Clavinet D6  5.0</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>41</td>
<td>Piano Fusion Str</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>22-Violins Leg LoVib_SR 3.0</td>
<td>White Grand XL 6.1</td>
<td>22-Violins Leg LoVib_SR 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>42</td>
<td class=odd>Synth Split</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>43</td>
<td>Nefertiti Mk I 3</td>
<td>EPiano1</td>
<td>3.04</td>
<td>Clavinet D6  5.0</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>44</td>
<td class=odd>Comp Piano</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>45</td>
<td>Ballad Layer EP</td>
<td>EPiano1</td>
<td>3.04</td>
<td>Ballad EP2  Sml 5.2</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>51</td>
<td class=odd>DX EPiano MW</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>52</td>
<td>Reflections</td>
<td>Undefined</td>
<td>3.04</td>
<td>Bright Grand YaS4  Lrg 5.3</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>53</td>
<td class=odd>Hots It</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>EP6 Sparkletop XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank O</td>
<td>54</td>
<td>Stratus</td>
<td>Undefined</td>
<td>3.04</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank O</td>
<td class=odd>55</td>
<td class=odd>Stratus Split</td>
<td class=odd>Undefined</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>EP8 Nefertiti XL 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>11</td>
<td>Living On A Pray</td>
<td>Grand</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>12</td>
<td class=odd>Living On A Pr 2</td>
<td class=odd>Grand</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>13</td>
<td>TreasureBrass MW</td>
<td>Synth</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>14</td>
<td class=odd>VersatileLead AT</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>15</td>
<td>Locked Out</td>
<td>User</td>
<td>3.04</td>
<td>Not found: (Unknown)</td>
<td>(No sound)</td>
<td>Royal Grand 3D YaS6 XL 5.4</td>
<td>LOCKED OUT OF HEAVEN</td>
<td>WARNING: program is missing 1 pianos/samples</td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>21</td>
<td class=odd>Maniac Arp</td>
<td class=odd>User</td>
<td class=odd>3.04</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>22</td>
<td>Maniac Prech Arp</td>
<td>User</td>
<td>3.04</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>23</td>
<td class=odd>Maniac</td>
<td class=odd>User</td>
<td class=odd>3.04</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd>EP1 Deep Timbre Lrg 6.0</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>24</td>
<td>Maniac Prechorus</td>
<td>Synth</td>
<td>3.04</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td>EP1 Deep Timbre Lrg 6.0</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>25</td>
<td class=odd>Get Lucky Synth</td>
<td class=odd>Lead</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>31</td>
<td>Titanium MW</td>
<td>Pad</td>
<td>3.04</td>
<td>EP8 Nefertiti XL 6.0</td>
<td>(No sound)</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>41</td>
<td class=odd>Soft Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>42</td>
<td>Saw Pad</td>
<td>Pad</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>43</td>
<td class=odd>Supersaw Pad</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>(No sound)</td>
<td class=odd>White Grand XL 6.1</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>44</td>
<td>Soft Octaves</td>
<td>Pad</td>
<td>3.04</td>
<td>White Grand XL 6.1</td>
<td>(No sound)</td>
<td>White Grand XL 6.1</td>
<td>OrchStrings Legato_KH 3.0</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>45</td>
<td class=odd>Supersoft Vocals</td>
<td class=odd>Pad</td>
<td class=odd>3.04</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>Small Choir Fem oh Soft_CL 3.0</td>
<td class=odd>Royal Grand 3D YaS6 XL 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>51</td>
<td>SynthStrings LFO</td>
<td>String</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>52</td>
<td class=odd>Orchestr Strings</td>
<td class=odd>String</td>
<td class=odd>3.04</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>OrchStrings Legato_KH 3.0</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
<tr>
<td>Program</td>
<td>Bank P</td>
<td>53</td>
<td>Strings Hold LFO</td>
<td>String</td>
<td>3.04</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>Soft Strings Leg LoVib_SR 3.1</td>
<td>DX7 FullTines  Lrg 5.4</td>
<td>(No sound)</td>
<td></td>
</tr>
<tr>
<td class=odd>Program</td>
<td class=odd>Bank P</td>
<td class=odd>54</td>
<td class=odd>Fast Strings</td>
<td class=odd>String</td>
<td class=odd>3.04</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>Soft Strings Leg LoVib_SR 3.1</td>
<td class=odd>DX7 FullTines  Lrg 5.4</td>
<td class=odd>(No sound)</td>
<td class=odd></td>
</tr>
</table></html>
    `;