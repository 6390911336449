import type {Preset} from "../Presets";
import {observable} from "mobx";

export const createDummyPresets: Preset[] = () => Array.from(Array(50).keys()).map(num => ({
    name: `Preset ${num + 1}`,
    programNumber: num,
    bankMSB: 0,
    bankLSB: 0
}));

export const getPresetsFromNordHTML = (html: string, nordStageVersion = 3) => {
    const cheerio = require('cheerio');

    const nordPresets = [];
    const $ = cheerio.load(html);

    const keys = [
        'partition',
        'bank',
        'location',
        'name',
        'category',
        'version',
        'pianoA',
        'sampleLibA',
        'pianoB',
        'sampLibB',
        'info'
    ];

    $('table tr').each(function() {
        if ($('td', this).length > 0) {
            const newPreset = {};
            $('td', this).each(function (i) {
                newPreset[keys[i]] = $(this).text();
            });
            nordPresets.push(newPreset);
        }
    });

    let programNumber = 0;
    let bankNumber = 0;
    let previousBankLetter = 'A';

    let naturalNordCounter = 11;

    const incrementNordCounter = () => {
        if (naturalNordCounter % 10 === 5) {
            if (naturalNordCounter === 55) {
                naturalNordCounter = 11;
            } else {
                naturalNordCounter += 6;
            }
        } else {
            naturalNordCounter++;
        }
    };

    return observable(nordPresets.map(nordPreset => {
        const bankLetter = nordPreset.bank.substr(-1, 1);

        if (nordStageVersion === 3) {
            if (bankLetter !== previousBankLetter && (bankLetter === 'F')) {
                programNumber = 0;
                bankNumber++;
            }

            if (programNumber === 125) {
                programNumber = 0;
                bankNumber++;
            }

            while (parseInt(nordPreset.location) !== naturalNordCounter) {
                programNumber++;
                incrementNordCounter();
            }
        } else if (nordStageVersion === 2) {
            bankNumber = bankLetter.charCodeAt(0) - 65;
            const [slotGroupNum, slotNum] = nordPreset.location.split(':').map(s => parseInt(s));
            programNumber = (slotGroupNum - 1) * 5 + slotNum - 1;
        }

        const preset = observable({
            name: nordPreset.name,
            programNumber: programNumber,
            bankMSB: 0,
            bankLSB: bankNumber
        });

        if (nordStageVersion === 2){
        //console.log(`${nordPreset.name} | bank ${bankNumber} | ${programNumber}`);
        }

        if (nordStageVersion === 3) {
            programNumber++;
            incrementNordCounter();
            previousBankLetter = bankLetter;
        }
        return preset;
    }));
};
