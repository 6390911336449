class LocalStorage {
    static getPresets() {
        const presetString = window.localStorage?.getItem('presets');
        if (presetString) {
            return JSON.parse(presetString);
        }
        return null;
    }

    static setPresets(presets) {
        //window.localStorage.setItem('presets', JSON.stringify(presets));
    }

    static getMidiInput(): string {
        return window.localStorage?.getItem('midiInput');
    }

    static setMidiInput(midiInput: string) {
        window.localStorage.setItem('midiInput', midiInput);
    }

    static getMidiOutput(): string {
        return window.localStorage?.getItem('midiOutput');
    }

    static setMidiOutput(midiOutput: string) {
        window.localStorage.setItem('midiOutput', midiOutput);
    }
}

export default LocalStorage;