import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useParams, useHistory} from "react-router-dom";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { observer } from "mobx-react-lite";
import { ProjectStoreContext } from "../../appstate/ProjectStore"
import {InstrumentStoreContext} from "../../appstate/InstrumentStore"

import PresetList from "../PresetList/PresetList"
import type {Preset} from "../../util/Presets"
import Midi, {MIDI_EVENT_TYPES} from "../../util/Midi"
import FormGroup from "@material-ui/core/FormGroup"
import {Button} from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import {PresetHelper} from "../../util/Presets"
import getSlug from "speakingurl"
import alert from '../Dialogs/AlertDialog';
import { getDeviceType } from "../../util/Misc";
import {observable} from "mobx"
import prompt from "../Dialogs/TextInputDialog"
import confirm from "../Dialogs/ConfirmationDialog"
import AddIcon from "@material-ui/icons/Add"
import styled from "styled-components"
import type {Instrument} from "../../appstate/InstrumentStore"
import ToggleButton from "@material-ui/lab/ToggleButton"

export const InstrumentSection = styled.section`
  .fullscreen-enabled & {
    margin: 1rem;
  }
`;

const ProjectName = styled.h1`
  .fullscreen-enabled & {
    color: white;
    margin: 1rem;
  }
`;

const PrevNextButton = styled(Link)`
  margin-right: 1rem;
  padding: 1rem;
  font-size: 1.2rem;
  .fullscreen-enabled & {
    color: white;
    margin: 1rem;
  }
`;

const serializePreset = (preset: Preset) => preset ? `(${preset.name}, ${preset.bankMSB}, ${preset.bankLSB}, ${preset.programNumber}, ${preset.instrument?.name})` : '(null)';

export const ProjectView = observer(({props}) => {
    const fullScreenHandle = useFullScreenHandle();
    const { projectId } = useParams();
    const history = useHistory();
    const projectStore = useContext(ProjectStoreContext);
    const instrumentStore = useContext(InstrumentStoreContext);

    const [project, setProject] = useState(projectStore.projects.find(project => project.id === projectId));
    const [selectedPreset, setSelectedPreset] = useState(null);
    const [lastReceivedPreset: Preset, setLastReceivedPreset] = useState(null);

    const [learnActive: boolean, setLearnActive] = useState(false);

    const [presetsPerPage, setPresetsPerPage] = useState(getDeviceType() === 'phone' ? 8 : 20);


    useEffect(() => {
        if (!project) {
            return;
        }

        // TODO: Es sollte mehr als ein MIDI Device erlaubt sein
        if (project.instrumentLinks.length > 0) {
            const instrument = project.instrumentLinks[0].instrument;

            const changeMidiDevice = () => {
                Midi.changeMidiInput(instrument.midiInputName);
                Midi.changeMidiOutput(instrument.midiOutputName);

                console.debug(`connected with ${instrument?.name}: ${instrument.midiInputName}`);
            };

            // TODO: Das ist nat�rlich hacky und geht so nicht.
            Midi.isInitialized ? changeMidiDevice() : setTimeout(changeMidiDevice, 2000);
        } else {
            // TODO: Das ist alles Unsinn. Ich mache das nur, damit man bei leerem Projekt erstmal das MIDI Device bekommt

            const instrumentThatHasMIDI = instrumentStore.instruments.find((i) => i.midiInputName);

            if (!instrumentThatHasMIDI) {
                console.debug('No instrument found that has a MIDI input configured');
                return;
            }

            const changeMidiDevice = () => {
                Midi.changeMidiInput(instrumentThatHasMIDI.midiInputName);
                Midi.changeMidiOutput(instrumentThatHasMIDI.midiOutputName);

                console.debug(`connected with ${instrumentThatHasMIDI?.name}: ${instrumentThatHasMIDI.midiInputName}`);
            };

            // TODO: Das ist nat�rlich hacky und geht so nicht.
            Midi.isInitialized ? changeMidiDevice() : setTimeout(changeMidiDevice, 2000);
        }

        projectStore.persistProjects();
    }, []);

    useEffect(() => {
        const foundProject = projectStore.projects.find(project => project.id === projectId);
        setProject(foundProject);
        projectStore.defaultProject = foundProject;
    }, [projectId]);

    useEffect(() => {
        const activePreset = project?.instrumentLinks[0]?.instrument.activePreset;
        activePreset && setSelectedPreset(activePreset);
        //console.log('setze selectedPreset auf activePreset');
    }, [project?.instrumentLinks[0]?.instrument.activePreset]);

    const onReceivedPresetChange = useCallback((e) => {
        const receivedPreset: Preset = e.detail.preset;
        const midiInputName: Preset = e.detail.midiInputName;

        const instrument = instrumentStore.getInstrumentByMidiInput(midiInputName);

        console.debug(e.detail);

        if (instrument) {
            let foundPreset = instrument.presets.find(p => PresetHelper.arePresetsEqual(p, receivedPreset));
            receivedPreset.name = foundPreset?.name || `Program ${receivedPreset.programNumber}`;
            receivedPreset.instrument = instrument;
        } else {
            console.debug('Received program change from MIDI device ' + midiInputName + ' but no instrument is assigned to this MIDI input!');
        }

        console.debug('instrument:', { ... instrument });

        if (learnActive) {
            if (!lastReceivedPreset || !PresetHelper.arePresetsEqual(lastReceivedPreset, receivedPreset)) {
                setLastReceivedPreset({...receivedPreset});
            }
        }

        if (!instrument.activePreset || !PresetHelper.arePresetsEqual(instrument.activePreset, receivedPreset)) {
            instrument.activePreset = receivedPreset;
            console.debug('setze instrument.activePreset, vormals ' + serializePreset(instrument.activePreset) + ', auf receivedPreset: ' + serializePreset(receivedPreset));
        }

        /*
        Brauchen wir ggf. alles nicht - aber nochmal pr�fen!
        if (instrument && project.instrumentLinks.length > 0) {
            const instrumentLink = project.instrumentLinks.find(i => i.id === instrument.id);
            if (instrumentLink) {
                const preset = instrumentLink.presets.find(preset => PresetHelper.arePresetsEqual(preset, receivedPreset));
                setSelectedPreset(preset || null)
            }
        }
         */
    }, [lastReceivedPreset, project, learnActive]);

    useEffect(() => {
        console.log('midi connect');
        window.addEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        return function cleanup() {
            window.removeEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        };
    }, [project, onReceivedPresetChange]);

    const onPresetClick = (e, preset: Preset, instrument: Instrument) => {
        Midi.sendPresetToCurrentOutput(preset); // TODO: Es sollte sp�ter nur gesendet werden, wenn es wirklich das richtige MIDI Device ist
        //setSelectedPreset(preset);
        instrument.activePreset = preset;
        //e.preventDefault(); // Maybe not necessary because it seems mousedown and touchstart won't fire both
    };

    const onRenamePreset = async() => {
        if (!selectedPreset) return;

        const newName = await prompt('Rename preset', 'Enter new preset name:', selectedPreset.name);
        if (newName) {
            const instrumentLink = project.instrumentLinks.find(i => i.id === selectedPreset.instrument?.id) || project.instrumentLinks[0]; // TODO: Das ist buggy, funktioniert nicht anders, crasht

            if (instrumentLink) {
                const foundPreset = instrumentLink.presets.find(p => p === selectedPreset);
                if (foundPreset) {
                    foundPreset.name = newName;
                    selectedPreset.name = newName;
                }
            }
        }
    };

    const onRemovePreset = async () => {
        const instrumentLink = project.instrumentLinks.find(i => i.id === selectedPreset.instrument?.id) || project.instrumentLinks[0]; // TODO: Das ist buggy, funktioniert nicht anders, crasht
        instrumentLink.presets = instrumentLink.presets.filter(preset => preset !== selectedPreset);
        setSelectedPreset(null);
    };

    const onClear = async () => {
        if (await confirm('Really?')) {
            project.instrumentLinks.splice(0, project.instrumentLinks.length);
            setSelectedPreset(null);
        }
    };

    const onAddLastReceivedPreset = () => {
        if (!lastReceivedPreset) return;

        if (!lastReceivedPreset.instrument) {
            alert('Unknown Instrument', 'The received preset is not a known Instrument. Make sure to assign the MIDI Inputs you use to an Instrument!');
            return;
        }

        // TODO: Stattdessen sollte projectStore.addPresetToProject verwendet werden, wie unten. Aber das funktioniert aus irgendeinem Grund nicht, daher auf die Schnelle so:
        /*
        let instrumentLink = project.instrumentLinks.find(i => i.id === lastReceivedPreset.instrument?.id);
        if (!instrumentLink) {
            instrumentLink = observable({
                id: lastReceivedPreset.instrument.id,
                instrument: lastReceivedPreset.instrument,
                presets: []
            });
            project.instrumentLinks.push(instrumentLink);
        }

        const presetToAdd = observable(lastReceivedPreset);
        instrumentLink.presets = [...instrumentLink.presets, presetToAdd];
        setSelectedPreset(presetToAdd);
         */

        // TODO: So funktioniert es leider nicht, die presetList zeigt das neue Preset nicht an mit array.push. s muss neu gesetzt werden.
        const addedPreset = projectStore.addPresetToProject(project, lastReceivedPreset);
        setSelectedPreset(addedPreset);
    };

    const onFullScreenClick = () => {
        if (fullScreenHandle.active) {
            fullScreenHandle.exit();
        } else {
            fullScreenHandle.enter();
        }
    };

    const onFullScreenChange = useCallback((isFullScreen, handle) => {
        const num = getDeviceType() === 'phone' ? 8 : 20;
        setPresetsPerPage(isFullScreen ? num * 2 : num);
    }, []);

    const onNewProjectClick = useCallback(async () => {
        const name = await prompt('New project', 'Enter project name', '');
        if (name) {
            const newProject = observable({
                id: projectStore.getUniqueSlugForName(name),
                name: name,
                instrumentLinks: observable([]),
            });
            projectStore.projects.push(newProject);

            history.push(`/projects/${newProject.id}`);
        }
    }, []);

    return (
        project && <div style={{ margin: '1rem', marginBottom: 130}}>
            <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange}>
                <PrevNextButton to={`/projects/${projectStore.projects[projectStore.projects.indexOf(project) - 1]?.id || project.id}`}>Prev</PrevNextButton>
                <PrevNextButton to={`/projects/${projectStore.projects[projectStore.projects.indexOf(project) + 1]?.id || project.id}`}>Next</PrevNextButton>
                <PrevNextButton href="javascript:" as={'a'} onClick={() => onNewProjectClick()}>New</PrevNextButton>
                <ProjectName>{project.name}</ProjectName>
                {project.instrumentLinks.map(instrumentLink => {
                    console.info('rendere presetlist: selectedPreset ist: ' + serializePreset(instrumentLink.instrument.activePreset));
                    return <InstrumentSection key={instrumentLink.instrument?.id}>
                        <h2 style={{ color: '#a5a5a5' }}><Link to={`/instruments/${instrumentLink.instrument?.id}`}>{instrumentLink.instrument?.name ?? 'Unknown instrument'}</Link></h2>

                        <PresetList
                            presets={instrumentLink.presets}
                            presetsPerPage={presetsPerPage}
                            selectedPreset={instrumentLink.instrument.activePreset}
                            onPresetClick={(e, preset) => onPresetClick(e, preset, instrumentLink.instrument)}
                            searchText=""
                        />
                    </InstrumentSection>;
                })}
            </FullScreen>

            <FormGroup row style={{ padding: '0.5rem', gap: '1rem', position: 'fixed', left: '0', bottom: '0', right: '0', background: 'black' }}>
                <Button variant="contained" color="primary" onClick={onFullScreenClick}>Fullscreen</Button>
                <Button variant="contained" color="primary" onClick={onRenamePreset} disabled={!selectedPreset}>Rename</Button>
                <Button variant="contained" color="primary" onClick={onRemovePreset} disabled={!selectedPreset}>Remove</Button>
                <Button variant="contained" color="primary" onClick={onClear}>Clear</Button>

                <FormControlLabel
                    control={
                        <Switch
                            checked={learnActive}
                            onChange={(event) => setLearnActive(event.target.checked)}
                            /*
                            checked={settingsStore.presetLearnActive}
                            onChange={(event) => settingsStore.presetLearnActive = event.target.checked}
                             */
                            color="primary"
                            name="presetLearnActiveToggle"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Listen"
                />
                <Button variant="contained" color="primary" onClick={onAddLastReceivedPreset} disabled={!lastReceivedPreset}>
                    <AddIcon />
                    Add {lastReceivedPreset ?  `${lastReceivedPreset.name}` + (project.instrumentLinks.length > 1 ? ` (${lastReceivedPreset.instrument.name})` : '') : 'Preset'}
                </Button>
            </FormGroup>

        </div>
    )
});

export default ProjectView;