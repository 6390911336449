import { observable, decorate, reaction } from "mobx";
import {createContext, useContext} from "react";
import getSlug from "speakingurl";
import type {Preset} from "../util/Presets"
import type {Instrument} from "./InstrumentStore"
import {InstrumentStore} from "./InstrumentStore"

export type Project = {
    id: string,
    name: string,
    instrumentLinks: InstrumentLink[]
};

export type InstrumentLink = {
    id: string,
    instrument: Instrument,
    presets: Preset[],
};

export class ProjectStore {
    projects: Project[] = [
        {
            name: 'Pub (24.02.)',
            instrumentLinks: []
        },
        {
            name: 'Jam Session',
            instrumentLinks: [],
        },
    ].map(project => ({...project, id: getSlug(project.name)}));

    defaultProject: Project = null;

    getProjectById(id: string) : Project | undefined {
        return this.projects.find(project => project.id === id);
    };

    getUniqueSlugForName(name: string): string {
        let slug = getSlug(name);
        if (!this.getProjectById(slug)) {
            return slug;
        }

        let counter = 2;
        let baseSlug = slug;

        const match = slug.match(/(\d+)$/);
        if (match) {
            counter = parseInt(match[1]) + 1;
            baseSlug = slug.slice(0, -match[0].length);
        }

        while (this.getProjectById(baseSlug + counter)) {
            counter++;
        }

        return baseSlug + counter;
    }

    persistProjects() {
        const projects = this.projects.map(project => ({
            id: project.id,
            name: project.name,
            instrumentLinks: project.instrumentLinks.map(i => ({
                id: i.instrument.id,
                presets: i.presets.map(p => ({
                    name: p.name,
                    programNumber: p.programNumber,
                    bankMSB: p.bankMSB,
                    bankLSB: p.bankLSB
                }))
            })),
        }));
        localStorage.setItem('Projects', JSON.stringify(projects));
    }

    loadProjectsFromLocaleStorage(instrumentStore: InstrumentStore) {
        const projects = JSON.parse(localStorage.getItem('Projects'));
        if (projects) {
            this.projects.clear();

            projects.forEach(p => {
                const newProject: Project = observable(p);

                newProject.instrumentLinks = observable(newProject.instrumentLinks);

                newProject.instrumentLinks.forEach(i => {
                    i.instrument = instrumentStore.getInstrumentById(i.id);
                    if (!i.instrument) {
                        alert('Could not restore instrument ' + i.id + ' in Project ' + newProject.name + '!');
                    }
                    i.presets = observable(i.presets);
                });

                this.projects.push(newProject);
            });
        }
    }

    addPresetToProject(project: Project, preset: Preset) {
        console.debug(`Versuche, Preset ${preset.name} zu Projekt ${project.name} hinzuzuf�gen. preset.instrument ist ${preset.instrument?.name}`);
        // TODO: Das schl�gt fehl, wenn preset.instrument nicht gesetzt ist. �berlegen, ob ein Preset grunds�tzlich
        // die Referenz auf das Instrument haben soll oder nicht.

        let instrumentLink = project.instrumentLinks.find(i => i.instrument.id === preset.instrument.id);
        if (!instrumentLink) {
            instrumentLink = observable({
                id: preset.instrument.id,
                instrument: preset.instrument,
                presets: observable([])
            });
            project.instrumentLinks.push(instrumentLink);
        }

        const presetToAdd = observable(preset);
        //instrumentLink.presets.push(presetToAdd);
        instrumentLink.presets = [...instrumentLink.presets, presetToAdd];

        return presetToAdd;
    }
}

decorate(ProjectStore, {
    projects: observable,
    defaultProject: observable
});

export const store = new ProjectStore();
export const ProjectStoreContext = createContext(store);

/*
reaction(() => JSON.stringify(ProjectStoreContext.projects), json => {
    localStorage.setItem('ProjectStore', JSON.stringify(ProjectStoreContext.projects));
    console.log(json);
}, {
    delay: 500
});
*/