import { observable, decorate } from "mobx";
import { createContext } from "react";
import {getPresetsFromNordHTML} from "../util/import/ImportPresets";
import {html as nordStage2PresetHTML} from "../util/import/nordStage2";
import {html as nordStage3PresetHTML} from "../util/import/nordStage3";
import getSlug from "speakingurl";
import type {Preset} from "../util/Presets"
import LocalStorage from "../util/LocalStorage"
import {Project} from "./ProjectStore"

export type Instrument = {
    id: string,
    name: string,
    image: string,
    presets: Preset[],
    activePreset?: Preset,
    midiInputName: string,
    midiOutputName: string,
};

export class InstrumentStore {
    instruments: Instrument[] = [
        {
            name: 'Nord Stage 2',
            image: 'images/nord-stage-2.jpg',
            presets: observable(getPresetsFromNordHTML(nordStage2PresetHTML, 2))/*.slice(0, 5)*/,
            activePreset: null,
            midiInputName: '',
            midiOutputName: '',
        },
        {
            name: 'Nord Stage 3',
            image: 'images/nord-stage-3.jpg',
            presets: observable(getPresetsFromNordHTML(nordStage3PresetHTML, 3))/*.slice(0, 5)*/,
            activePreset: null,
            midiInputName: '', //'Nord Stage 3 MIDI',
            midiOutputName: '', //'Nord Stage 3 MIDI',
        },
        {
            name: 'Virus TI',
            image: 'images/access-virus-ti.png',
            presets: [],
            activePreset: null,
            midiInputName: '',
            midiOutputName: '',
        },
        {
            name: 'Yamaha MoXF 6',
            image: 'images/yamaha-moxf.png',
            presets: [],
            activePreset: null,
            midiInputName: '',
            midiOutputName: '',
        },
        {
            name: 'Moog Sub 37',
            image: 'images/moog-sub-37.png',
            presets: [],
            activePreset: null,
            midiInputName: '',
            midiOutputName: '',
        },
        {
            name: 'Reface CP',
            image: 'images/yamaha-reface-cp.png',
            presets: [],
            activePreset: null,
            midiInputName: '',
            midiOutputName: '',
        },
        {
            name: 'Nord Lead A1',
            image: 'images/nord-lead-a1.jpg',
            presets: [],
            activePreset: null,
            midiInputName: navigator.userAgent.includes('Firefox') ? 'Nord Lead A1 MIDI' : 'mi.1',
            midiOutputName: navigator.userAgent.includes('Firefox') ? 'Nord Lead A1 MIDI' : 'mi.1',
        },
    ].map(instrument => ({...instrument, id: getSlug(instrument.name)}));

    getInstrumentById = (id: string) : Instrument | undefined => {
        return this.instruments.find(i => i.id === id);
    };

    getInstrumentByMidiInput = (midiInputName: string) : Instrument | undefined => {
        return this.instruments.find(i => i.midiInputName === midiInputName);
    };

    getInstrumentByMidiOutput = (midiOutputName: string) : Instrument | undefined => {
        return this.instruments.find(i => i.midiOutputName === midiOutputName);
    };

    getUniqueSlugForName = (name: string): string => {
        let slug = getSlug(name);
        if (!this.getInstrumentById(slug)) {
            return slug;
        }

        let counter = 2;
        let baseSlug = slug;

        const match = slug.match(/(\d+)$/);
        if (match) {
            counter = parseInt(match[1]) + 1;
            baseSlug = slug.slice(0, -match[0].length);
        }

        while (this.getInstrumentById(baseSlug + counter)) {
            counter++;
        }

        return baseSlug + counter;
    }

    persistInstruments() {
/*
        const instruments = this.instruments.map(instrument => ({
            id: project.id,
            name: project.name,
            instrumentLinks: project.instrumentLinks.map(i => ({
                id: i.instrument.id,
                presets: i.presets.map(p => ({
                    name: p.name,
                    programNumber: p.programNumber,
                    bankMSB: p.bankMSB,
                    bankLSB: p.bankLSB
                }))
            })),
        }));

*/

        const seen = [];
        localStorage.setItem('instruments', JSON.stringify(this.instruments, function(key, val) {
            if (val != null && typeof val == "object") {
                if (seen.indexOf(val) >= 0) {
                    return;
                }
                seen.push(val);
            }
            return val;
        }));
    }

    loadInstrumentsFromLocaleStorage() {
        const instruments = JSON.parse(localStorage.getItem('instruments'));
        if (instruments && instruments.length > 0) {
            this.instruments.clear();

            instruments.forEach((i: Instrument) => {
                i.activePreset = null;
                this.instruments.push(observable(i));
            });
        }
    }
}

decorate(InstrumentStore, {
    instruments: observable,
});

export const store = new InstrumentStore();
export const InstrumentStoreContext = createContext(store);