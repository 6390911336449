import styled from 'styled-components';
import {device} from "../../util/MediaQueries";
import {Root as PresetListPageRoot} from "./PresetListPage";

export const PresetListWrapper = styled.ul`
    margin: 0;
    padding: 0;
    display: grid;
    list-style: none;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    
    * {
    min-height: 60px;
    }

    @media ${device.mobileL} {
        grid-template-columns: 1fr 1fr 1fr;
        * {
            min-height: 50px;
        }
    }  
  
    @media ${device.tablet} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }  
`;

export const PresetPageContainer = styled.ul`
  list-style: none;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  gap: 1rem;
  
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  
  /*
  >*:nth-child(2n) {
    background: black;
  }
   */
`;