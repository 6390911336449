import React, {useEffect, useImperativeHandle, useState} from "react";
import PresetButton from "../PresetButton/PresetButton";
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {device} from "../../util/MediaQueries"
import {PresetHelper} from "../../util/Presets"

export const Root = styled.li`
    margin: 0;
    padding: 0 0 1rem;
    height: fit-content;
    
    scroll-snap-align: center;
    scroll-snap-stop: always;
        
    display: grid;
    grid-gap: 20px;    
    
    grid-template-columns: 1fr 1fr;
    
    * {
      min-height: 60px;
    }
    
    @media ${device.mobileL} {
        grid-template-columns: 1fr 1fr 1fr;
        * {
            min-height: 50px;
        }
    }  
  
    @media ${device.tablet} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }  
`;


const PresetListPage = observer(({presets, pageIndex, presetsPerPage, selectedPreset, multiSelection, onPresetClick}) => {
    return (
        <Root>
            {presets.slice(pageIndex * presetsPerPage, pageIndex * presetsPerPage + presetsPerPage).map((preset, key) => (
                <PresetButton key={key} preset={preset} isSelected={selectedPreset && PresetHelper.arePresetsEqual(preset, selectedPreset)} isMultiSelected={multiSelection?.includes(preset)} onClick={onPresetClick} />
            ))}
        </Root>
    );
});

export default PresetListPage;