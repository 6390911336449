import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InstrumentList from "../InstrumentList/InstrumentList";
import PresetList from "../PresetList/PresetList";
import type {Preset} from "../../util/Presets";
import Midi, { MIDI_EVENT_TYPES } from "../../util/Midi";
import prompt from "../Dialogs/TextInputDialog";
import confirm from '../Dialogs/ConfirmationDialog';

import Switch from '@material-ui/core/Switch';
import { PresetHelper } from "../../util/Presets";

import { observer } from "mobx-react-lite";
import {observable, remove} from "mobx";
import { InstrumentStoreContext } from "../../appstate/InstrumentStore";
import { SettingsStoreContext } from "../../appstate/SettingsStore";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { fade, makeStyles } from "@material-ui/core/styles";
import { getDeviceType } from "../../util/Misc";
import {ProjectStoreContext} from "../../appstate/ProjectStore"
import ToggleButton from "@material-ui/lab/ToggleButton"
import AddIcon from "@material-ui/icons/Add"
import alert from "../Dialogs/AlertDialog"
import getSlug from "speakingurl"

const presetsPerPage = getDeviceType() === 'phone' ? 10 : 20;

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    buttonBar: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default observer(({ props }) => {
    const { instrumentId } = useParams();
    const history = useHistory();
    const instrumentStore = useContext(InstrumentStoreContext);
    const projectStore = useContext(ProjectStoreContext);
    const settingsStore = useContext(SettingsStoreContext);

    //console.log('rerender InstrumentView');

    const presetList = React.useRef(null);

    const instrument = instrumentStore.instruments.find(instrument => instrument.id === instrumentId);
    const presets = instrument?.presets;
    const [selectedPreset: Preset[], setSelectedPreset] = useState(instrument?.activePreset || null);
    const [searchText, setSearchText] = useState('');
    const [lastReceivedPreset: Preset[], setLastReceivedPreset] = useState(null);
    const [autoAddEnabled: boolean, setAutoAddEnabled] = useState(false);
    const [listenForNewPresets: boolean, setListenForNewPresets] = useState(true);

    const classes = useStyles();

    const [multiSelection, setMultiSelection] = useState([]);
    const [selectModeEnabled, setSelectModeEnabled] = useState(false);

    const getSelectedPresets = () => multiSelection.length > 0 ? multiSelection : [selectedPreset];

    useEffect(() => {
        window.addEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        return function cleanup() {
            window.removeEventListener(MIDI_EVENT_TYPES.PRESET_CHANGE, onReceivedPresetChange);
        };
    }, [presets, autoAddEnabled, listenForNewPresets]);

    // TODO: Es sollte mehr als ein MIDI Device erlaubt sein
    useEffect(() => {
        const changeMidiDevice = () => {
            Midi.changeMidiInput(instrument.midiInputName);
            Midi.changeMidiOutput(instrument.midiOutputName);
        };

        // TODO: Das ist nat�rlich hacky und geht so nicht.
        Midi.isInitialized ? changeMidiDevice() : setTimeout(changeMidiDevice, 2000);
    }, []);

    const onPresetClick = (e, preset: Preset) => {
        console.log(preset);

        e.preventDefault(); // Maybe not necessary because it seems mousedown and touchstart won't fire both

        if (selectModeEnabled) {
            if (multiSelection.includes(preset)) {
                setMultiSelection(multiSelection.filter(p => p !== preset));
            } else {
                setMultiSelection([...multiSelection, preset]);
            }
        } else {
            Midi.sendPresetToCurrentOutput(preset);
            setSelectedPreset(preset);
            instrument.activePreset = preset;
        }
    };

    const addPreset = (newPreset: Preset) => {
        //presets.push(newPreset);
        instrument.presets = [...instrument.presets, newPreset];
    };

    const onAppendPreset = () => {
        let newPreset;

        if (presets.length > 0) {
            newPreset = observable({
                name: `Preset ${presets.length + 1}`,
                programNumber: presets.slice(-1)[0].programNumber + 1,
                bankMSB: presets.slice(-1)[0].bankMSB,
                bankLSB: presets.slice(-1)[0].bankLSB
            });
        } else {
            newPreset = observable({
                name: `Preset 1`,
                programNumber: 0,
                bankMSB: 0,
                bankLSB: 0
            });
        }

        addPreset(newPreset);
        const presetToSelect = instrument.presets.slice(-1)[0];
        setSelectedPreset(presetToSelect); // ############# TODO: Aus irgendeinem Grund funktioniert das nicht! Das Preset wird nicht selektiert, weil es sich in dem
        instrument.activePreset = presetToSelect;
        //Observable Array irgendwie um ein anderes Array handelt
        //presetList.current.scrollToPreset(instrument.presets[instrument.presets.length - 1]);
        presetList.current.scrollToLastPreset();
    };

    const onRenamePreset = async () => {
        const newName = await prompt('Rename preset', 'Enter new preset name:', selectedPreset.name);
        if (newName) {
            selectedPreset.name = newName;
        }
    };

    const onRemovePreset = async () => {
        if (await confirm('Remove preset', `Do you really want to remove '${selectedPreset.name}'?`)) {
            instrument.presets = instrument.presets.filter(preset => preset !== selectedPreset);
            setSelectedPreset(null);
            instrument.activePreset = null;
        }
    };

    const onScrollToSelectedPreset = () => {
        presetList.current.scrollToPreset(selectedPreset);
    };

    const addAnonymousPreset = (preset: Preset) => {
        const newPreset = observable({
            ...preset,
            name: `Preset ${instrument.presets.length + 1}`
            //name: `Bank ${PresetHelper.getBankFromPresetByManufacturer(preset, manufacturers.CLAVIA)}, Preset ${preset.programNumber}`
        });

        addPreset(newPreset);
        setSelectedPreset(newPreset);
        instrument.activePreset = newPreset;
    };

    const onReceivedPresetChange = useCallback((e) => {
        const receivedPreset: Preset = e.detail.preset;
        const midiInputName: Preset = e.detail.midiInputName;

        const preset = presets.find(preset => PresetHelper.arePresetsEqual(preset, receivedPreset));
        if (preset) {
            setSelectedPreset(preset);
            instrument.activePreset = preset;
        } else {
            const instrumentByMidiInput = instrumentStore.getInstrumentByMidiInput(midiInputName);
            if (instrumentByMidiInput) {
                receivedPreset.name = `Program ${receivedPreset.programNumber}`;
                receivedPreset.instrument = instrumentByMidiInput;
                setLastReceivedPreset({ ...receivedPreset });

                console.debug(receivedPreset);
            }

            /*
            if (autoAddEnabled) {
                addAnonymousPreset(receivedPreset);
            }
            else {
             */
            //if (listenForNewPresets) {
            //    // nichts zu tun
            //} else {
                setSelectedPreset(null);
                instrument.activePreset = null;
            //}
        }
    }, [presets, autoAddEnabled, instrument]);

    const onAddLastReceivedPreset = () => {
        if (!lastReceivedPreset) return;

        if (!lastReceivedPreset.instrument) {
            alert('Unknown Instrument', 'The received preset is not a known Instrument. Make sure to assign the MIDI Inputs you use to an Instrument!');
            return;
        }

        addAnonymousPreset(lastReceivedPreset);
    };

    const onAddToDefaultProject = useCallback((e) => {
        getSelectedPresets().forEach((preset) => {
            projectStore.addPresetToProject(projectStore.defaultProject, {
                ...preset, instrument
            });
        });
    }, [getSelectedPresets, projectStore, selectedPreset, instrument]);

    const onAddToNewProject = useCallback(async (e) => {
        const name = await prompt('New project', 'Enter project name', '');
        if (name) {
            const newProject = observable({
                id: projectStore.getUniqueSlugForName(name),
                name: name,
                instrumentLinks: observable([]),
            });
            projectStore.projects.push(newProject);

            getSelectedPresets().forEach((preset) => {
                projectStore.addPresetToProject(newProject, {
                    ...preset, instrument
                });
            });

            history.push(`/projects/${newProject.id}`);
        }
    }, [getSelectedPresets, projectStore, selectedPreset, instrument]);

    const onChangeSelectMode = useCallback((e) => {
        if (selectModeEnabled) {
            setMultiSelection([]);
        }
        setSelectModeEnabled(!selectModeEnabled);
    }, [selectModeEnabled]);

    const onSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    return instrument && (
        <div style={{padding: 20}}>
            <Typography variant="h5" gutterBottom>
                {instrument.name}
            </Typography>

            {instrument.presets.length > 0 &&
                <FormGroup row>
                    <TextField
                        style={{paddingBottom: 24}}
                        placeholder="Search for presets"
                        margin="normal"
                        value={searchText}
                        onChange={onSearchInputChange}
                    />
                    <IconButton size="small" onClick={() => setSearchText('')}>
                        <ClearIcon />
                    </IconButton>
                </FormGroup>
            }

            {/*

            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>
            */}

            <PresetList
                presets={presets}
                presetsPerPage={presetsPerPage}
                searchText={searchText}
                selectedPreset={instrument.activePreset}
                multiSelection={multiSelection}
                onPresetClick={onPresetClick}
                ref={presetList}
            />

            <FormGroup row style={{ marginTop: 30 }} className={classes.buttonBar}>
                <Button variant="contained" color="primary" onClick={onAddToDefaultProject} disabled={!projectStore.defaultProject || getSelectedPresets() > 0 /*|| projectStore.defaultProject?.instrumentLinks.some(i => i.presets.some(p => PresetHelper.arePresetsEqual(p, selectedPreset)))*/}>Add to {projectStore.defaultProject?.name ?? 'Project'}</Button>
                { multiSelection.length > 0 && <Button variant="contained" color="primary" onClick={onAddToNewProject} disabled={multiSelection.length === 0}>Add to new project</Button> }
                <Button variant="contained" color="primary" onClick={onRenamePreset} disabled={!selectedPreset}>Rename</Button>
                <Button variant="contained" color="primary" onClick={onRemovePreset} disabled={!selectedPreset}>Remove</Button>
                <Button variant="contained" color="primary" onClick={onAppendPreset}>Add</Button>
                <ToggleButton variant="contained" color="primary" value={selectModeEnabled} onChange={onChangeSelectMode}>Select</ToggleButton>
                {/*<Button variant="contained" color="primary" onClick={onScrollToSelectedPreset} disabled={!selectedPreset}>Scroll to selected</Button>*/}

                {/*<FormControlLabel
                    control={
                        <Switch
                            checked={autoAddEnabled}
                            onChange={(event) => setAutoAddEnabled(event.target.checked)}
                            color="primary"
                            name="presetLearnActiveToggle"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Automatically add received presets"
                />*/}
                <FormControlLabel
                    control={
                        <Switch
                            checked={listenForNewPresets}
                            onChange={(event) => setListenForNewPresets(event.target.checked)}
                            color="primary"
                            name="listenToggle"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Listen"
                />
                <Button variant="contained" color="primary" onClick={onAddLastReceivedPreset} disabled={!lastReceivedPreset} style={{ 'display' : listenForNewPresets && lastReceivedPreset ? 'initial' : 'none'}}>
                    <AddIcon />
                    Add {lastReceivedPreset ? `${lastReceivedPreset.name}` : 'Preset'}
                </Button>
            </FormGroup>
        </div>
    );
});